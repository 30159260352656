
import dotenv from 'dotenv'
dotenv.config()

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { mask } from "vue-the-mask";
import wysiwyg from "vue-wysiwyg";
import ApiClient from "../plugins/apiClient";
import urls from "../plugins/urls";
import VApplication from "../components/VApplication.vue";
import VCrud from '../components/VCrud.vue';
import VFileUploader from '../components/VFileUploader.vue';
import VDatetimePicker from '../components/VDatetimePicker.vue';
import VDateRange from "../components/VDateRange.vue";
import VLangText from '../components/VLangText.vue';
import VSearch from '../components/VSearch.vue';
import moment from 'moment';
import VTreeBrowser from "../components/VTreeBrowser.vue";

const VUE_APP_BASE_URL = process.env.NODE_ENV == "development" ? process.env.VUE_APP_BASE_URL_DEV : process.env.VUE_APP_BASE_URL_PROD;

const apiClient = new ApiClient({
    baseUrl: VUE_APP_BASE_URL
});

Vue.prototype.apiClient = apiClient;
Vue.prototype.urls = urls;

Vue.use(wysiwyg, {
    hideModules: { "bold": true },
    maxHeight: "200px"
});
Vue.directive("mask", mask);

Vue.component("v-application", VApplication);
Vue.component('v-crud', VCrud);
Vue.component('v-file-uploader', VFileUploader);
Vue.component('v-datetime-picker', VDatetimePicker);
Vue.component('v-lang-text', VLangText);
Vue.component("v-date-range", VDateRange);
Vue.component("v-search", VSearch);
Vue.component("v-tree-browser", VTreeBrowser);

Vue.use(Vuetify);

Vue.prototype.$copy = (json) => JSON.parse(JSON.stringify(json))

Vue.mixin({
    data: () => ({
        masks: {
            phone: "+998 (##) ### - ## - ##",
            hour_mask: "##-##"
        },
        crud_limit: 10,
        crud_search: ''
    }),
    computed: {

        fieldRules() {
            return {
                required: value => {
                    if (value == 0) return true
                    if (!value) {
                        return this.$t('this_field_can_not_be_blank')
                    }
                    if (value instanceof Array) {
                        if (value.length == 0) {
                            return this.$t('this_field_can_not_be_blank')
                        }
                    }
                    return !!value || this.$t('this_field_can_not_be_blank')
                },
                lengthRange: (value, min_length, max_length) => {
                    if (!value) {
                        return this.$t('this_field_can_not_be_blank')
                    }
                    if (value.length < min_length || value.length > max_length) {
                        return `${this.$t('length_range_should_be')} [${min_length} ${max_length}]`
                    }
                    return true
                },
                validPhone: (value) => {
                    if (!value) {
                        return this.$t('this_field_can_not_be_blank')
                    }
                    value = value.replace(/[^0-9]/g, "");
                    if (value.length != 12) {
                        return this.$t('should_be_valid_phone')
                    }
                    return true;
                },
            }
        },
    },
    methods: {
        setToken(token) {
            this.$store.commit('setToken', token);
        },
        setUser(user) {
            this.$store.commit('setUser', user);
        },

        date_ddmmyy(timestamp) {
            if (typeof timestamp == typeof 5) {
                let day: any = new Date(timestamp).getDate()
                day = day < 10 ? `0${day}` : day
                let month: any = new Date(timestamp).getMonth()
                month = month < 9 ? `0${month + 1}` : (month + 1)
                const year = new Date(timestamp).getFullYear()
                return `${day}.${month}.${year}`
            }
            else return timestamp
        },
        date_ddmmyy_hhmm(timestamp) {
            if (!timestamp) return ''
            return moment(timestamp).format('DD-MM-YY HH:mm')
        },
        date_yyyy_mm_dd(date) {
            return moment(date).format('YYYY-MM-DD')
        },
        goBack() {
            this.$router.go(-1);
        }
    }
})

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#6F74F6',
                secondary: '#474EFB',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#8CC748',
                warning: '#FFC107'
            },
        },
    },
})