export default {
  data: () => ({
    groups: [],
    search_group: "",
  }),
  computed: {
    searched_groups() {
      const searched_groups = [];
      this.groups.map((u) => {
        searched_groups.push({
          text: u.name,
          value: u._id,
        });
      });
      return searched_groups;
    },
  },
  methods: {
    async groupGetPagin() {
      const params = {
        limit: 1000,
        page: this.page ? this.page : 1,
        search: this.search_group,
      };
      const group_get_pagin = JSON.parse(
        JSON.stringify(this.urls.group_get_pagin)
      );
      group_get_pagin.url += `?limit=${params.limit}&page=${params.page}&search=${params.search}`;
      const result = this.apiClient.convertApiResult(
        await this.apiClient.apiResult(group_get_pagin)
      );
      if (result && result.result.data instanceof Array) {
        this.groups = result.result.data;
        this.pagination_length = Math.ceil(result.result.total / 10);
      } else {
        this.groups = [];
        this.pagination_length = 1;
      }
    },
    async groupCreate() {
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(this.urls.group_create, this.group)
      );
    },
    async groupUpdate() {
      const group_update = JSON.parse(JSON.stringify(this.urls.group_update));
      group_update.url += `${this.group._id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(group_update, this.group)
      );
    },
    async groupDelete(group) {
      const group_delete = JSON.parse(JSON.stringify(this.urls.group_delete));
      group_delete.url += `${group._id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(group_delete)
      );
    },
  },
};
