export default {
  data: () => ({
    practices: [],
    search_practice: "",
    filter: {
      isInProcess: false,
      adminId: null,
    },
  }),
  methods: {
    async practiceGetPagin(type?, search?) {
      const params: any = {
        limit: 1000,
        page: this.page ? this.page : 1,
        search: this.search_practice,
        isInProcess: this.filter.isInProcess,
      };
      if (this.filter.adminId) {
        params.adminId = this.filter.adminId;
      }
      if (this.filter.groupId) {
        params.groupId = this.filter.groupId;
      }
      if (type) {
        params.type = type;
      }
      const practice_get_pagin = JSON.parse(
        JSON.stringify(this.urls.practice_get_pagin)
      );
      practice_get_pagin.url += `?limit=${params.limit}&page=${params.page}`;
      if (params.isInProcess)
        practice_get_pagin.url += `&isInProcess=${params.isInProcess}`;
      if (params.adminId)
        practice_get_pagin.url += `&adminId=${params.adminId}`;
      if (params.groupId)
        practice_get_pagin.url += `&groupId=${params.groupId}`;
      if (params.type) practice_get_pagin.url += `&type=${params.type}`;
      console.log(search);
      const practice_search = search || params.search;
      if (practice_search)
        practice_get_pagin.url += `&search=${practice_search}`;
      const result = this.apiClient.convertApiResult(
        await this.apiClient.apiResult(practice_get_pagin)
      );
      if (result && result.result.data instanceof Array) {
        this.practices = result.result.data;
        this.pagination_length = Math.ceil(result.result.total / 10);
      } else {
        this.practices = [];
        this.pagination_length = 1;
      }
    },
    async practiceResultGet(id: string, groupId?: string) {
      const practice_result_get = JSON.parse(
        JSON.stringify(this.urls.practice_result_get)
      );
      practice_result_get.url += `${id}`;
      if (groupId) practice_result_get.url += `?groupId=${groupId}`;
      const response = await this.apiClient.convertApiResult(
        await this.apiClient.apiResult(practice_result_get)
      );
      return response.result;
    },
    async practiceCreate(params) {
      const result = await this.apiClient.convertApiResult(
        await this.apiClient.apiResult(this.urls.practice_create, params)
      );
      return result;
    },
    async practiceUserRetry(params) {
      const result = await this.apiClient.convertApiResult(
        await this.apiClient.apiResult(this.urls.practice_user_retry, params)
      );
      return result;
    },
    async practiceGetById(id: string) {
      const practice_get_by_id = JSON.parse(
        JSON.stringify(this.urls.practice_get_by_id)
      );
      practice_get_by_id.url += `${id}`;
      const result = await this.apiClient.convertApiResult(
        await this.apiClient.apiResult(practice_get_by_id)
      );
      return result;
    },
    async practiceUpdate(id: string, params) {
      const practice_update = JSON.parse(
        JSON.stringify(this.urls.practice_update)
      );
      practice_update.url += `${id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(practice_update, params)
      );
    },
    async practiceDelete(id: string) {
      const practice_delete = JSON.parse(
        JSON.stringify(this.urls.practice_delete)
      );
      practice_delete.url += `${id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(practice_delete)
      );
    },
  },
};
