import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-application',[_c(VCard,[_c(VCardTitle,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.add}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t("questions")))],1)],1),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.questions),function(item,i){return _c(VCol,{key:i,attrs:{"cols":"md-6 sm-6"}},[_c(VCard,[_c(VCard,{attrs:{"color":"#385F73","dark":""}},[_c(VCardTitle,{staticClass:"text-h5",domProps:{"textContent":_vm._s(item.content)}}),_c(VCardSubtitle,{domProps:{"textContent":_vm._s(item.explanation)}})],1),_c(VCardActions,[_c(VSpacer),_vm._v(" "+_vm._s(_vm.date_ddmmyy_hhmm(item.createdAt))+" "),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-circle")])],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }