<template>
  <v-application :toolbar_title="$t('practices')">
    <v-crud
      :addRole="'practiceCreate'"
      :editRole="'practiceUpdate'"
      :deleteRole="'practiceDelete'"
      :resultRole="'practiceResult'"
      :addName="'add_practices'"
      :headers="headers"
      :data="practices"
      :toolbar_title="$t('practices')"
      :paginationLength="pagination_length"
      :page="page"
      @page-changed="pageChanged"
      @add-clicked="addPractices"
      @edit-clicked="rowClick"
      @info-clicked="resultClick"
      :detailDialog="detail_dialog"
      no-select
      @update-data="searchPractice"
    >
      <template v-slot:toolbar>
        <v-toolbar class="mt-5" :elevation="0">
          <v-col cols="12" sm="6" md="4">
            <v-checkbox v-model="filter.isInProcess" :label="$t('inProcess')" />
          </v-col>
          <v-col v-if="currentRole['practiceAll']" cols="12" sm="6" md="4">
            <v-autocomplete
              class="mx-2"
              :width="100"
              v-model="filter.adminId"
              :label="$t('admin')"
              :items="admins"
              item-text="name"
              item-value="_id"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              class="mx-2"
              :width="100"
              v-model="filter.groupId"
              :label="$t('group')"
              :items="groups"
              item-text="name"
              item-value="_id"
              clearable
            />
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              class="mx-2"
              :width="100"
              v-model="filter.categoryId"
              :label="$t('category')"
              :items="categories"
              item-text="name"
              item-value="_id"
              clearable
            />
          </v-col> -->
        </v-toolbar>
      </template>
    </v-crud>
  </v-application>
</template>

<script>
import Practices from "../../mixins/practices";
import Admin from "../../mixins/admin";
import Role from "../../mixins/role";
import Group from "../../mixins/group";

export default {
  data: () => ({
    pagination_length: 1,
    page: 1,
    detail_dialog: false,
  }),
  mixins: [Practices, Admin, Role, Group],
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "name",
        },
        {
          text: this.$t("start_date"),
          value: "startsAt",
        },
        {
          text: this.$t("could_start_date"),
          value: "couldStartsAt",
        },
        {
          text: this.$t("is_active"),
          value: "isActive",
        },
        {
          text: this.$t("is_for_group"),
          value: "isForGroup",
        },
        {
          text: this.$t("admin"),
          value: "admin.name",
        },
        {
          text: null,
          align: "right",
          value: "actions",
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.practiceGetPagin('timedPractice');
      },
    },
    page() {},
    detail_dialog() {
      if (!this.detail_dialog) {
        this.resetForm();
      }
    },
  },
  methods: {
    pageChanged(page) {
      this.page = page;
    },
    rowClick(item) {
      this.user = this.$copy(item);
      this.$router.push(`/practices/detail?id=${item._id}&step=1`);
    },
    resultClick(item) {
      this.$router.push(`/practice-result?id=${item._id}`);
    },
    addPractices() {
      this.$router.push(`/practices/detail?step=1`);
    },
    searchPractice(search) {
      this.practiceGetPagin('timedPractice',search);  
    }
  },
  mounted() {
    this.practiceGetPagin('timedPractice');
    this.adminsGetPagin();
    this.groupGetPagin();
  },
};
</script>
