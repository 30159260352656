import navs from './navs'

const userData = localStorage.getItem('user');
const user = userData && JSON.parse(userData) ? JSON.parse(userData) : null
const token = localStorage.getItem('token');

const navStatesStr = localStorage.getItem('navStates')
const navStates = navStatesStr && JSON.parse(navStatesStr) instanceof Array ? JSON.parse(navStatesStr) : []

const minivariant = localStorage.getItem('minivariant') == 'true';
const snackbar = { show: false, text: "something", }
const expandedStr = localStorage.getItem('expanded')
const expanded = expandedStr && JSON.parse(expandedStr) ? JSON.parse(expandedStr) : []

const roleStr = localStorage.getItem('role')
const role = roleStr && JSON.parse(roleStr) ? JSON.parse(roleStr) : {}

export default {
    snackbar,
    user,
    token,
    navs: navs.navs,
    navStates,
    minivariant,
    expanded,
    role,
};
