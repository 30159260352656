<template>
  <v-application :toolbar_title="$t('admins')">
    <v-crud
      :addRole="'adminCreate'"
      :editRole="'adminUpdate'"
      :deleteRole="'adminDelete'"
      :addName="'add_admin'"
      :headers="headers"
      :data="admins"
      :toolbar_title="$t('admins')"
      :paginationLength="pagination_length"
      :page="page"
      @row-click="rowClick"
      @cancel-clicked="resetForm"
      :dialogWidth="600"
      :cardTitle="$t('admin_details')"
      @page-changed="pageChanged"
      @add-clicked="detail_dialog = true"
      :detailDialog="detail_dialog"
      @save-clicked="save"
      :saveDisabled="saveDisabled"
      @update-data="searchRole"
      no-select
      can-delete
      @delete-clicked="deleteClicked"
    >
      <template v-slot:detail>
        <v-form v-model="is_valid" v-on:submit.prevent>
          <v-text-field
            v-model="admin.name"
            :label="$t('name')"
            :rules="[fieldRules.lengthRange(admin.name, 3, 100)]"
            ref="name"
            autocomplete="off"
          />
          <v-text-field
            v-model="admin.login"
            :label="$t('login')"
            :rules="[fieldRules.lengthRange(admin.login, 3, 100)]"
            ref="login"
            autocomplete="off"
          />
          <v-text-field
            v-model="admin.password"
            :label="$t('password')"
            ref="password"
            autocomplete="off"
          />
          <v-autocomplete
            v-model="admin.roleId"
            :label="$t('role')"
            ref="name"
            autocomplete="off"
            :items="roles"
            item-value="_id"
            item-text="name"
          />
        </v-form>
      </template>
    </v-crud>
  </v-application>
</template>

<script>
import Roles from "../../mixins/role";
import Admins from "../../mixins/admin";

export default {
  data: () => ({
    admin: {
      name: "",
      login: "",
      roleId: null,
    },
    pagination_length: 1,
    page: 1,
    detail_dialog: false,
    is_valid: false,
    emailRules: [
      (v) => (v ? /.+@.+\..+/.test(v) || "E-mail must be valid" : true),
    ],
  }),
  mixins: [Roles, Admins],
  computed: {
    roleFields() {
      const nonBools = [
        "name",
        "_id",
        "createdAt",
        "updatedAt",
        "deletedAt",
        "__v",
        "number",
        "version",
      ];
      return Object.keys(this.role).filter((k) => !nonBools.includes(k));
    },
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "name",
        },
        {
          text: this.$t("login"),
          value: "login",
        },
        {
          text: this.$t("role"),
          value: "role.name",
        },
        {
          text: null,
          align: "right",
          value: "table_actions",
        },
      ];
    },
    form() {
      const admin = {
        name: this.admin.name,
        login: this.admin.login,
        roleId: this.admin.roleId,
      };
      return admin;
    },
    saveDisabled() {
      let formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;
      });
      return formHasErrors;
    },
  },
  watch: {
    page() {
      this.adminsGetPagin();
    },
    detail_dialog() {
      if (!this.detail_dialog) {
        this.resetForm();
      }
    },
  },
  methods: {
    getItemText(user) {
      return `${user.name || ""} ${user.login || ""} ${
        user.phone || ""
      }`.trim();
    },
    async save() {
      let result;
      if (!this.admin._id) {
        result = await this.adminCreate();
      } else {
        result = await this.adminUpdate();
      }
      if (result) {
        this.resetForm();
        this.adminsGetPagin();
      }
    },
    async deleteClicked(admin) {
      const result = await this.adminDelete(admin);
      if (result) {
        this.resetForm();
        this.adminsGetPagin();
      }
    },
    searchRole(search) {
      this.search_admin = search;
      this.adminsGetPagin();
    },
    pageChanged(page) {
      this.page = page;
    },
    resetForm() {
      Object.keys(this.form).forEach((f) => {
        if (this.$refs[f]) this.$refs[f].reset();
      });
      this.detail_dialog = false;
      this.admin = {
        name: "",
        login: "",
        password: "",
        roleId: null,
      };
    },
    rowClick(item) {
      this.admin = this.$copy(item);
      this.detail_dialog = true;
    },
  },
  mounted() {
    this.rolesGetPagin();
    this.adminsGetPagin();
  },
};
</script>
