<template>
  <v-application :toolbar_title="$t('roles')">
    <v-crud
      :addRole="'role'"
      :editRole="'role'"
      :deleteRole="'role'"
      :addName="'add_role'"
      :headers="headers"
      :data="roles"
      :toolbar_title="$t('roles')"
      :paginationLength="pagination_length"
      :page="page"
      @row-click="rowClick"
      @cancel-clicked="resetForm"
      :dialogWidth="600"
      :cardTitle="$t('role_details')"
      @page-changed="pageChanged"
      @add-clicked="detail_dialog = true"
      :detailDialog="detail_dialog"
      @save-clicked="save"
      :saveDisabled="saveDisabled"
      @update-data="searchRole"
      no-select
      can-delete
      @delete-clicked="deleteClicked"
    >
      <template v-slot:detail>
        <v-form v-model="is_valid" v-on:submit.prevent>
          <v-text-field
            v-model="role.name"
            :label="$t('name')"
            :rules="[fieldRules.lengthRange(role.name, 3, 100)]"
            ref="name"
            autocomplete="off"
          />
          <div
            v-for="(item, item_index) of roleFields"
            v-bind:key="'role' + item_index"
          >
            <v-checkbox v-model="role[item]" :label="$t(item)" />
          </div>
        </v-form>
      </template>
    </v-crud>
  </v-application>
</template>

<script>
import Roles from "../../mixins/role";

const defaultRole = {
  name: "",
  user: false,
  userCreate: false,
  userUpdate: false,
  userDelete: false,
  practice: false,
  practiceAll: false,
  practiceCreate: false,
  practiceUpdate: false,
  practiceDelete: false,
  practiceResult: false,
  homework: false,
  homeworkAll: false,
  homeworkCreate: false,
  homeworkUpdate: false,
  homeworkDelete: false,
  homeworkResult: false,
  group: false,
  groupCreate: false,
  groupUpdate: false,
  groupDelete: false,
  admin: false,
  adminCreate: false,
  adminUpdate: false,
  adminDelete: false,
  role: false,
};

export default {
  data: () => ({
    role: Object.assign({}, defaultRole),
    pagination_length: 1,
    page: 1,
    detail_dialog: false,
    is_valid: false,
    emailRules: [
      (v) => (v ? /.+@.+\..+/.test(v) || "E-mail must be valid" : true),
    ],
  }),
  mixins: [Roles],
  computed: {
    roleFields() {
      const nonBools = [
        "name",
        "_id",
        "createdAt",
        "updatedAt",
        "deletedAt",
        "__v",
        "number",
        "version",
        "isDefault",
      ];
      return Object.keys(this.role).filter((k) => !nonBools.includes(k));
    },
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "name",
        },
        {
          text: null,
          align: "right",
          value: "table_actions",
        },
      ];
    },
    form() {
      const role = {
        name: this.role.name,
      };
      return role;
    },
    saveDisabled() {
      let formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;
      });
      return formHasErrors;
    },
  },
  watch: {
    page() {
      this.rolesGetPagin();
    },
    detail_dialog() {
      if (!this.detail_dialog) {
        this.resetForm();
      }
    },
  },
  methods: {
    getItemText(user) {
      return `${user.name || ""} ${user.login || ""} ${
        user.phone || ""
      }`.trim();
    },
    async save() {
      let result;
      if (!this.role._id) {
        result = await this.roleCreate();
      } else {
        result = await this.roleUpdate();
      }
      if (result) {
        this.resetForm();
        this.rolesGetPagin();
      }
    },
    async deleteClicked(role) {
      const result = await this.roleDelete(role);
      if (result) {
        this.resetForm();
        this.rolesGetPagin();
      }
    },
    searchRole(search) {
      this.search_role = search;
      this.rolesGetPagin();
    },
    pageChanged(page) {
      this.page = page;
    },
    resetForm() {
      Object.keys(this.form).forEach((f) => {
        if (this.$refs[f]) this.$refs[f].reset();
      });
      this.detail_dialog = false;
      this.role = Object.assign({}, defaultRole);
    },
    rowClick(item) {
      this.role = Object.assign({}, defaultRole, item);
      this.detail_dialog = true;
    },
  },
  mounted() {
    this.rolesGetPagin();
  },
};
</script>
