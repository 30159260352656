export default {
  data: () => ({
    roles: [],
    search_role: "",
    currentRole: {}
  }),
  computed: {
    searched_groups() {
      const searched_groups = [];
      this.roles.map((u) => {
        searched_groups.push({
          text: u.name,
          value: u._id,
        });
      });
      return searched_groups;
    },
  },
  methods: {
    async rolesGetPagin() {
      const params = {
        limit: 1000,
        page: this.page ? this.page : 1,
        search: this.search_role,
      };
      const role_get_pagin = JSON.parse(
        JSON.stringify(this.urls.role_get_pagin)
      );
      role_get_pagin.url += `?limit=${params.limit}&page=${params.page}&search=${params.search}`;
      const result = this.apiClient.convertApiResult(
        await this.apiClient.apiResult(role_get_pagin)
      );
      if (result && result.result.data instanceof Array) {
        this.roles = result.result.data;
        this.pagination_length = Math.ceil(result.result.total / 10);
      } else {
        this.roles = [];
        this.pagination_length = 1;
      }
    },
    async roleCreate() {
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(this.urls.role_create, this.role)
      );
    },
    async roleUpdate() {
      const role_update = JSON.parse(JSON.stringify(this.urls.role_update));
      role_update.url += `${this.role._id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(role_update, this.role)
      );
    },
    async roleCurrent() {
      const role_current = JSON.parse(JSON.stringify(this.urls.role_current));
      const result = this.apiClient.convertApiResult(
        await this.apiClient.apiResult(role_current, this.role)
      );
      if (result) {
        this.currentRole = result.result;
        this.$store.commit('setRole', result.result || {});
      }
    },
    async roleDelete(role) {
      const role_delete = JSON.parse(JSON.stringify(this.urls.role_delete));
      role_delete.url += `${role._id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(role_delete)
      );
    },
  },

  beforeMount() {
    this.currentRole = this.$store.state.role;
  },
};
