<template>
  <draggable
    :group="{ name: 'g1' }"
    class="ma-0 pa-0"
    tag="div"
    :list="categories"
  >
    <div
      v-for="node in categories"
      :key="node.name"
      class="ma-0 pa-0"
      style="border: solid green 1px"
    >
      <v-divider />
      <v-data-table
        class="ma-0 pa-0"
        item-key="_id"
        :headers="[
          {
            value: 'header',
            divider: true,
            class: 'with-divider',
          },
          { value: 'name', align: 'left' },
          { value: 'test', width: '80%', align: 'left' },
        ]"
        :items="[node]"
        hide-default-footer
        hide-default-header
      >
        <template v-slot:[`item.header`]="{ item }">
          <span class="ma-0 pa-0" @click="nodeClicked(item._id)">
            {{
              item.isPractice
                ? "-1"
                : !item.children
                ? "0"
                : isExpanded(item._id)
                ? "1"
                : "2"
            }}
          </span>
        </template>
      </v-data-table>

      <!-- <span @click="emitClick(node)">
        {{ node.name }}
      </span> -->
      <v-tree-browser
        class="ma-0 pa-0"
        v-if="isExpanded(node._id) && node.children"
        :categories="node.children"
        :depth="depth + 1"
        @onClick="emitClick"
        :expanded="expanded"
        :isExpanded="isExpanded"
        @nodeClick="nodeClicked"
      />
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "v-tree-browser",
  props: {
    categories: Array,
    expanded: Array,
    withPractise: Boolean,
    isExpanded: Function,
    depth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // expanded: [],
    };
  },
  methods: {
    nodeClicked(nodeId) {
      this.$emit("nodeClick", nodeId);
    },
    emitClick(node) {
      console.log("emitClick", node.name);
      this.$emit("onClick", node);
    },
  },
  components: {
    draggable,
  },
  computed: {},
};
</script>

<style scoped>
/* .with-divider {
  border-right: 1px solid grey;
  width: 5px !important;
} */
</style>