<template>
  <div>
    <v-file-input
      v-model="file"
      filled
      :label="label"
      :rules="rulesArr"
      :prepend-icon="prependIcon"
      :ref="refstr"
      @change="uploadFile"
      :accept="acceptFiles"
    >
      <template v-if="uploading_image" v-slot:append>
        <v-progress-circular :value="upload_percentage"/>
      </template>
    </v-file-input>
  </div>
</template>

<script>
import axios from "axios";
import Userable from "../mixins/userable";

export default {
  name: "v-file-uploader",
  mixins: [Userable],
  data: () => ({
    file: null,
    uploading_image: false,
    upload_percentage: 0
  }),
  props: {
    value: [String, Object],
    label: String,
    rulesArr: Array,
    prependIcon: String,
    refstr: String,
    acceptFiles: String
  },
  watch: {
    value() {
      if (!this.value) {
        this.file = null;
        return;
      }
      this.file = { name: this.value, is_name: true };
    }
  },
  methods: {
    async uploadFile(file) {
      if (!file) {
        this.$emit("input", null);
        return;
      }

      if (file.is_name) {
        return;
      }

      const headers = {
        Authorization: `Bearer ${this.computedToken}`,
        "Content-type": "application/json"
      };
      const formdata = new FormData();
      formdata.append("file", file);
      this.uploading_image = true;

      try {
        const result = await axios.post(
          `${this.apiClient.baseUrl}/upload-file`,
          formdata,
          {
            headers: headers,
            onUploadProgress: function(progressEvent) {
              if (progressEvent.loaded == progressEvent.total) {
                this.uploading_image = false;
              }
              this.upload_percentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this)
          }
        );
        if (result && result.data && result.data.code == 0) {
          const file_path = result.data.data.path;
          this.$emit("input", file_path);
        } else {
          this.$emit("input", null);
          this.setSnackbar({  })
        }
      } catch (error) {
        console.log(error)
        this.apiClient.setSnackbar(error.response.data)
        this.$emit("input", null);
      }
      this.uploading_image = false;
    }
  },
  mounted() {
    if (!this.value) return;
    this.file = { name: this.value };
  }
};
</script>
