export default {
  data: () => ({
    categories: [],
    categoriesWithPractices: [],
    search_category: "",
  }),
  computed: {},
  methods: {
    async categoriesGet() {
      const category_get = JSON.parse(
        JSON.stringify(this.urls.category_get)
      );
      const result = this.apiClient.convertApiResult(
        await this.apiClient.apiResult(category_get)
      );
      if (result.result) {
        this.categories = result.result
      }
      else {
        this.categories = []
      }
    },
    async categoriesWithPracticesGet() {
      const category_with_practices_get = JSON.parse(
        JSON.stringify(this.urls.category_with_practices_get)
      );
      const result = this.apiClient.convertApiResult(
        await this.apiClient.apiResult(category_with_practices_get)
      );
      if (result.result) {
        this.categoriesWithPractices = result.result
      }
      else {
        this.categoriesWithPractices = []
      }
    },
    async categoryCreate() {
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(this.urls.category_create, this.category)
      );
    },
    async categoryUpdate() {
      const category_update = JSON.parse(JSON.stringify(this.urls.category_update));
      category_update.url += `${this.category._id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(category_update, this.category)
      );
    },
    async categoryDelete(category) {
      const category_delete = JSON.parse(JSON.stringify(this.urls.category_delete));
      category_delete.url += `${category._id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(category_delete)
      );
    },
  },
};
