import "vue-wysiwyg/dist/vueWysiwyg.css"
import Vue from 'vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';
import langs from './langs/index';

Vue.config.productionTip = false

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',
  messages: langs
});

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h("router-view")
}).$mount('#app')
