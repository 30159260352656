<template>
  <v-application>
    <v-card>
      <v-card-title
        ><v-btn color="primary" @click="add"
          ><v-icon>mdi-plus</v-icon>{{ $t("questions") }}</v-btn
        ></v-card-title
      >
      <v-container fluid>
        <v-row dense>
          <v-col v-for="(item, i) in questions" :key="i" cols="md-6 sm-6">
            <v-card>
              <v-card color="#385F73" dark>
                <v-card-title
                  class="text-h5"
                  v-text="item.content"
                ></v-card-title>
                <v-card-subtitle v-text="item.explanation"></v-card-subtitle>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                {{ date_ddmmyy_hhmm(item.createdAt) }}
                <v-btn icon>
                  <v-icon color="primary">mdi-pencil-circle</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-application>
</template>

<script>
import Questions from "../../mixins/questions";
export default {
  data: () => ({
    items: [
      {
        color: "#1F7087",
        src: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
        title: "Supermodel",
        artist: "Foster the People",
      },
      {
        color: "#952175",
        src: "https://cdn.vuetifyjs.com/images/cards/halcyon.png",
        title: "Supermodel",
        artist: "Ellie Goulding",
      },
    ],
  }),
  mixins: [Questions],

  watch: {},
  methods: {
    add(){
      this.$router.push(`/questions/detail`);
    }
  },
  mounted() {
    this.questionGet()
  },
};
</script>
