import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',{staticClass:"ma-0 pa-0",attrs:{"group":{ name: 'g1' },"tag":"div","list":_vm.categories}},_vm._l((_vm.categories),function(node){return _c('div',{key:node.name,staticClass:"ma-0 pa-0",staticStyle:{"border":"solid green 1px"}},[_c(VDivider),_c(VDataTable,{staticClass:"ma-0 pa-0",attrs:{"item-key":"_id","headers":[
        {
          value: 'header',
          divider: true,
          class: 'with-divider',
        },
        { value: 'name', align: 'left' },
        { value: 'test', width: '80%', align: 'left' },
      ],"items":[node],"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:`item.header`,fn:function({ item }){return [_c('span',{staticClass:"ma-0 pa-0",on:{"click":function($event){return _vm.nodeClicked(item._id)}}},[_vm._v(" "+_vm._s(item.isPractice ? "-1" : !item.children ? "0" : _vm.isExpanded(item._id) ? "1" : "2")+" ")])]}}],null,true)}),(_vm.isExpanded(node._id) && node.children)?_c('v-tree-browser',{staticClass:"ma-0 pa-0",attrs:{"categories":node.children,"depth":_vm.depth + 1,"expanded":_vm.expanded,"isExpanded":_vm.isExpanded},on:{"onClick":_vm.emitClick,"nodeClick":_vm.nodeClicked}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }