
const navs = [
    {
        title: 'user',
        type: 'item',
        icon: 'mdi-account-tie',
        color: 'blue',
        link: '/user',
        role: 'user'
    },
    // {
    //     title: 'questions',
    //     type: 'item',
    //     icon: 'mdi-help-circle-outline',
    //     color: 'blue',
    //     link: '/questions',
    //     role: 'admin'
    // },
    {
        title: 'homeworks',
        type: 'item',
        icon: 'mdi-home',
        color: 'blue',
        link: '/homework',
        role: 'homework'
    },
    {
        title: 'practices',
        type: 'item',
        icon: 'mdi-alpha-p-circle',
        color: 'blue',
        link: '/practice',
        role: 'practice'
    },
    {
        title: 'group',
        type: 'item',
        icon: 'mdi-select-group',
        color: 'blue',
        link: '/group',
        role: 'group'
    },
    {
        title: 'category',
        type: 'item',
        icon: 'mdi-shape-outline',
        color: 'blue',
        link: '/category',
        role: 'category'
    },
    {
        title: 'admin',
        type: 'item',
        icon: 'mdi-account-settings',
        color: 'blue',
        link: '/admin',
        role: 'admin'
    },
    {
        title: 'role',
        type: 'item',
        icon: 'mdi-security',
        color: 'blue',
        link: '/role',
        role: 'role'
    },
]

export default {
    navs
}
