<template>
  <v-autocomplete
    v-model="model"
    v-bind:items="items"
    item-text="text"
    item-value="value"
    :label="label"
    class="ma-0 pa-0"
    append-icon=""
    v-on:update:search-input="searchItems"
    no-filter
    :clearable="clearable"
    hide-no-data
    @click="searchItems('')"
    :loading="loading"
    :outlined="outlined"
    :dense="dense"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-html="data.item.text" />
        <v-list-item-subtitle
          v-html="data.item.additional ? data.item.additional : ''"
        />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "v-search",
  data: () => ({
    model: null,
    last_search: "123",
    choosean: false,
    loading: false,
  }),
  watch: {
    model() {
      if (this.model) {
        this.choosean = true;
      } else {
        this.choosean = false;
      }
      this.$emit("input", this.model);
    },
    value() {
      this.model = this.value;
    },
  },
  props: {
    items: Array,
    label: String,
    searchFunc: Function,
    value: String,
    outlined: Boolean,
    dense: Boolean,
    clearable: Boolean,
  },
  methods: {
    async searchItems(search) {
      if (this.choosean) return;
      if (typeof search != "string") return;
      if (this.last_search == search) return;
      this.last_search = search;
      this.loading = true;
      await this.searchFunc(search);
      this.loading = false;
    },
  },
  mounted() {
    this.model = this.value;
  },
};
</script>
