<template>
  <v-application :toolbar_title="$t('categories')">
    <v-tree-browser
      :with-practise="true"
      :categories="categoriesWithPractices"
      @onClick="nodeWasClicked"
      :expanded="expanded"
      :isExpanded="isExpanded"
      @nodeClick="nodeClicked"
    />
    <v-crud
      :addName="'add_category'"
      :headers="headers"
      :data="categories"
      :toolbar_title="$t('categories')"
      @row-click="rowClick"
      @cancel-clicked="resetForm"
      :dialogWidth="600"
      :cardTitle="$t('category_details')"
      @add-clicked="detail_dialog = true"
      :detailDialog="detail_dialog"
      @save-clicked="save"
      :saveDisabled="saveDisabled"
      @update-data="categoriesGet"
      no-select
      can-delete
      @delete-clicked="deleteClicked"
    >
      <template v-slot:detail>
        <v-form v-model="is_valid" v-on:submit.prevent>
          <v-text-field
            v-model="category.name"
            :label="$t('name')"
            :rules="[fieldRules.lengthRange(category.name, 3, 100)]"
            ref="name"
            autocomplete="off"
          />
          <v-tree-browser
            :categories="categories"
            @onClick="nodeWasClicked"
            :expanded="expandedCategory"
            :isExpanded="isExpandedCategory"
            @nodeClick="categoryNodeClicked"
          />
        </v-form>
      </template>
    </v-crud>
  </v-application>
</template>

<script>
import Category from "../../mixins/category";

export default {
  data: () => ({
    category: {
      name: null,
      categoryId: null,
    },
    detail_dialog: false,
    is_valid: false,
    emailRules: [
      (v) => (v ? /.+@.+\..+/.test(v) || "E-mail must be valid" : true),
    ],
    expanded: [],
    expandedCategory: [],
    depth: 1,
  }),
  mixins: [Category],
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "name",
        },
      ];
    },
    form() {
      const category = {
        name: this.category.name,
      };
      return category;
    },
    saveDisabled() {
      let formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;
      });
      return formHasErrors;
    },
  },
  watch: {
    detail_dialog() {
      if (!this.detail_dialog) {
        this.resetForm();
      }
    },
    categories: {
      deep: true,
      handler() {
        console.log(this.categories);
      },
    },
  },
  methods: {
    isExpanded(nodeId) {
      return this.expanded.indexOf(nodeId) !== -1;
    },
    nodeClicked(nodeId) {
      if (!this.isExpanded(nodeId)) {
        this.expanded.push(nodeId);
      } else {
        this.expanded.splice(this.expanded.indexOf(nodeId), 1);
      }
      this.$store.commit("setExpanded", this.expanded);
    },
    isExpandedCategory(nodeId) {
      return this.expandedCategory.indexOf(nodeId) !== -1;
    },
    categoryNodeClicked(nodeId) {
      if (!this.isExpandedCategory(nodeId)) {
        this.expandedCategory.push(nodeId);
      } else {
        this.expandedCategory.splice(this.expanded.indexOf(nodeId), 1);
      }
    },
    nodeWasClicked(node) {
      console.log("NODE", node.name);
      this.category.categoryId = node._id;
      console.log(this.category);
    },
    getItemText(user) {
      return `${user.name || ""} ${user.login || ""} ${
        user.phone || ""
      }`.trim();
    },
    async save() {
      let result;
      if (!this.category._id) {
        result = await this.categoryCreate();
      } else {
        result = await this.categoryUpdate();
      }
      if (result) {
        this.resetForm();
        this.categoriesGet();
      }
    },
    async deleteClicked(category) {
      const result = await this.categoryDelete(category);
      if (result) {
        this.resetForm();
        this.categoriesGet();
      }
    },
    resetForm() {
      Object.keys(this.form).forEach((f) => {
        if (this.$refs[f]) this.$refs[f].reset();
      });
      this.detail_dialog = false;
      this.category = {
        name: null,
        categoryId: null,
      };
    },
    rowClick(item) {
      this.category = this.$copy(item);
      this.detail_dialog = true;
    },
  },
  mounted() {
    this.categoriesGet();
    this.categoriesWithPracticesGet();
  },
  beforeMount() {
    this.expanded = this.$store.state.expanded;
  },
};
</script>

<style scoped>
.node {
  text-align: left;
  font-size: 18px;
}

.type {
  margin-right: 10px;
}
</style>
