
export default {
    data: () => ({
        questions: [],
    }),
    methods: {
        async questionGetPagin() {
            const params = {
                limit: 5,
                page: this.page || 1
            }
            const practice_get_pagin = JSON.parse(JSON.stringify(this.urls.practice_get_pagin));
            practice_get_pagin.url += `?limit=${params.limit}&page=${params.page}`;
            const result = this.apiClient.convertApiResult(
                await this.apiClient.apiResult(practice_get_pagin)
            );
            if (result && result.result.data instanceof Array) {
                this.practices = result.result.data;
                this.pagination_length = Math.ceil(result.result.total / 10)
            }
            else {
                this.practices = []
                this.pagination_length = 1
            }
        },
        async questionCreate() {
            const result = await this.apiClient.convertApiResult(
                await this.apiClient.apiResult(this.urls.question_create, this.question)
            );
            return result
        },
        async questionGet() {
            const result = await this.apiClient.convertApiResult(
                await this.apiClient.apiResult(this.urls.question_get)
            );
            if (result) {
                this.questions = result.result.data
            }
            return result
        },
        async questionGetById(id: string) {
            const practice_get_by_id = JSON.parse(JSON.stringify(this.urls.practice_get_by_id));
            practice_get_by_id.url += `${id}`
            const result = await this.apiClient.convertApiResult(
                await this.apiClient.apiResult(practice_get_by_id)
            );
            return result;
        },
        async questionUpdate(id: string) {
            const practice_update = JSON.parse(JSON.stringify(this.urls.practice_update));
            practice_update.url += `${id}`
            return this.apiClient.convertApiResult(
                await this.apiClient.apiResult(practice_update, this.practicesParams)
            );
        },
        async questionDelete(id: string) {
            const practice_delete = JSON.parse(JSON.stringify(this.urls.practice_delete));
            practice_delete.url += `${id}`
            return this.apiClient.convertApiResult(
                await this.apiClient.apiResult(practice_delete)
            );
        }
    }
}
