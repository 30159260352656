export default {
    setUser(state, user) {
        state.user = user;
        localStorage.setItem("user", JSON.stringify(user));
    },
    setToken(state, token) {
        state.token = token;
        localStorage.setItem("token", token);
    },
    setRole(state, role) {
        state.role = role;
        localStorage.setItem("role", JSON.stringify(role));
    },
    signOut(state) {
        state.user = null
        state.token = null
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        localStorage.clear()
    },
    setNavStates(state, navs) {
        state.setNavStates = navs;
        localStorage.setItem('setNavStates', JSON.stringify(navs))
    },
    setMinivariant(state, value) {
        state.minivariant = value;
        localStorage.setItem('minivariant', value);
    },
    setSnackbar(state, snackbar) {
        state.snackbar = snackbar;
    },
    setExpanded(state, value) {
        state.expanded = value;
        localStorage.setItem('expanded', JSON.stringify(value));
    },
};
