<template>
  <v-application :toolbar_title="$t('practices')">
    <div class="card-container">
      <div class="card-left">
        <v-card class="pa-5" :elevation="1" :loading="cardLoading">
          <div @click="practiceClicked" class="pointer-cursor text-h5">
            <!-- <v-icon>mdi-menu</v-icon> -->
            {{ practicesParams.name || `New ${practicesParams.type === 'homework' ? 'Homework' : 'Practice'}` }}
          </div>
          <v-list>
            <v-draggable v-model="sections">
              <v-list-item
                class="ma-0 pa-0 my-2"
                v-for="(item, section_index) in sections"
                v-bind:key="'item_' + section_index"
              >
                <v-list-item-title
                  @click="sectionClicked(item)"
                  class="pa-5 pointer-cursor"
                  :style="
                    'border: 1px solid ' +
                    (currentSection == item._id ? 'black;' : 'lightgray;')
                  "
                >
                  <v-icon>mdi-menu</v-icon>
                  {{ item.name || "New Section" }}
                </v-list-item-title>
              </v-list-item>
            </v-draggable>
          </v-list>
          <v-btn
            v-if="couldAddSection"
            class="ml-3"
            :elevation="0"
            color="primary"
            @click="
              sections.push({
                name: 'New Section #' + (1 + sections.length),
                content: '',
                duration: 0,
                break: 0,
                questions: [],
              })
            "
            >Add Section
          </v-btn>
        </v-card>
      </div>
      <div class="divider"></div>
      <div class="card-right">
        <v-card v-if="isPractice" :elevation="0">
          <v-card-actions class="my-5">
            <v-spacer></v-spacer>
            <v-btn
              v-if="currentRole['practiceDelete']"
              :elevation="0"
              class="mr-2"
              @click="deleteModel = true"
            >
              <v-icon>mdi-delete</v-icon>
              {{ $t("delete") }}
            </v-btn>
            <v-btn
              :disabled="savePracticeDisabled"
              color="primary"
              class="mr-2"
              @click="savePractice"
              :elevation="0"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
          <v-card-text class="my-5 pb-5">
            <form class="mr-5 ml-5">
              <v-text-field
                v-model="practicesParams.name"
                :label="$t('name')"
                :rules="[fieldRules.lengthRange(practicesParams.name, 3, 100)]"
                ref="name"
                autocomplete="off"
              />

              <v-layout row class="mb-5 mt-5">
                <v-flex class="px-3 pt-3" sm="6" md="6">
                  <DatePicker
                    class="elevation-1 rounded text--black"
                    v-model="practicesParams.startsAt"
                    :clearable="false"
                    placeholder="Start date"
                    :type="'datetime'"
                    format="YYYY-MM-DD HH:mm"
                    :showSecond="false"
                    showMinute
                    :minute-step="1"
                    value-type="timestamp"
                  />
                </v-flex>
                <v-flex class="px-3 pt-3" sm="6" md="6">
                  <DatePicker
                    class="elevation-1 rounded text--black"
                    v-model="practicesParams.couldStartsAt"
                    :clearable="false"
                    placeholder="Could Start date"
                    :type="'datetime'"
                    format="YYYY-MM-DD HH:mm"
                    :showSecond="false"
                    showMinute
                    :minute-step="1"
                    value-type="timestamp"
                  />
                </v-flex>
              </v-layout>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="practicesParams.isActive"
                    :label="$t('is_active')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="practicesParams.couldReview"
                    :label="$t('could_review')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-switch
                    v-model="practicesParams.isForGroup"
                    :label="$t('is_for_group')"
                  />
                </v-col>
                <v-col
                  v-if="practicesParams.isForGroup"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-autocomplete
                    v-model="practicesParams.groupIds"
                    :label="$t('groups')"
                    ref="groups"
                    autocomplete="off"
                    :items="groups"
                    item-text="name"
                    multiple
                    item-value="_id"
                    chips
                  />
                </v-col>
              </v-row>
            </form>
          </v-card-text>
        </v-card>
        <div v-else>
          <v-card-actions class="my-5 practices-header">
            {{ sectionParams.name || "New Section" }}
            <v-spacer></v-spacer>

            <v-btn :elevation="0" class="mr-2" @click="cloneModel = true">
              <v-icon>mdi-content-copy</v-icon>
              {{ $t("clone") }}
            </v-btn>

            <v-btn :elevation="0" class="mr-2" @click="deleteModel = true">
              <v-icon>mdi-delete</v-icon>
              {{ $t("delete") }}
            </v-btn>
            <v-btn
              :disabled="loadingSection || saveSectionDisabled"
              :loading="loadingSection"
              color="primary"
              class="mr-2"
              @click="saveSection"
              :elevation="0"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
          <v-card :elevation="0">
            <v-card-text class="my-5">
              <form class="mr-5 ml-5">
                <v-text-field
                  v-model="sectionParams.name"
                  :label="$t('name')"
                  :rules="[fieldRules.lengthRange(sectionParams.name, 3, 100)]"
                  ref="name"
                  autocomplete="off"
                />
                <v-text-field
                  v-model="sectionParams.content"
                  :label="$t('content')"
                  ref="content"
                  autocomplete="off"
                />

                <v-text-field
                  v-if="practicesParams.type !== 'homework'"
                  v-model.number="sectionParams.duration"
                  type="number"
                  :label="$t('duration')"
                  :rules="[fieldRules.required(sectionParams.duration)]"
                  ref="duration"
                  autocomplete="off"
                />

                <v-text-field
                  v-if="practicesParams.type !== 'homework'"
                  v-model.number="sectionParams.break"
                  type="number"
                  :label="$t('break')"
                  :rules="[fieldRules.required(sectionParams.break)]"
                  ref="break"
                  autocomplete="off"
                />
                <v-select
                  v-model="sectionParams.type"
                  :label="$t('type')"
                  ref="type"
                  :items="[
                    {
                      text: 'Math',
                      value: 'math',
                    },
                    {
                      text: 'English',
                      value: 'english',
                    },
                  ]"
                />
                <v-switch
                  v-model="sectionParams.shuffle"
                  :label="$t('shuffle')"
                />
                <v-switch
                  v-model="sectionParams.shuffleAsnwers"
                  :label="$t('shuffle_answers')"
                />
              </form>
            </v-card-text>
          </v-card>
          <v-draggable v-model="sectionParams.questions">
            <v-card
              class="my-2"
              :elevation="0"
              :color="questionProblem(questionParams) ? 'yellow' : ''"
              v-for="(
                questionParams, question_index
              ) in sectionParams.questions"
              v-bind:key="'question_' + question_index"
            >
              <v-card-title>
                <v-icon class="d-flex mx-2">mdi-menu</v-icon>
                {{ `Question #${1 + question_index}` }}
                <v-spacer></v-spacer>

                <v-btn-toggle class="mx-2">
                  <v-btn
                    outlined
                    @click="dublicateQuestion(question_index)"
                    :class="questionProblem(questionParams) ? 'yellow' : ''"
                  >
                    Dublicate
                  </v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="pa-0 ma-0"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :class="questionProblem(questionParams) ? 'yellow' : ''"
                      >
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <v-btn
                      class="red--text"
                      @click="sectionParams.questions.splice(question_index, 1)"
                    >
                      Delete
                    </v-btn>
                  </v-menu>
                </v-btn-toggle>
                <v-btn
                  icon
                  @click="
                    questionParams.isOpen
                      ? (questionParams.isOpen = false)
                      : (questionParams.isOpen = true)
                  "
                >
                  <v-icon v-if="questionParams.isOpen"> mdi-chevron-up </v-icon>
                  <v-icon v-else>mdi-chevron-down </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="questionParams.isOpen">
                <v-layout px-2>
                  <v-flex md6 mx-2>
                    <v-select
                      v-model="questionParams.type"
                      :label="$t('type')"
                      :items="['option', 'input']"
                      @change="typeChanged(question_index)"
                    />
                  </v-flex>
                  <v-flex md6 mx-2>
                    <v-select
                      v-model="questionParams.column"
                      :label="$t('column')"
                      :items="['single', 'double']"
                    />
                  </v-flex>
                </v-layout>

                <v-layout px-2>
                  <v-flex md12 mx-2>
                    <label class="text-h6">{{ $t("content") }}</label>
                    <!-- <wysiwyg v-model="questionParams.content" /> -->
                    <VueEditor
                      :id="'question_content' + question_index"
                      v-model="questionParams.content"
                    />
                  </v-flex>
                </v-layout>
                <v-layout px-2>
                  <v-flex md12 mx-2>
                    <label class="text-h6">{{ $t("title") }}</label>

                    <VueEditor
                      :id="'question_title' + question_index"
                      v-model="questionParams.title"
                    />
                  </v-flex>
                </v-layout>
                <v-layout px-2>
                  <v-flex md12 mx-2>
                    <label class="text-h6">{{ $t("explanation") }}</label>

                    <VueEditor
                      :id="'question_explanation' + question_index"
                      v-model="questionParams.explanation"
                    />
                  </v-flex>
                </v-layout>

                <v-layout px-2 mt-5>
                  <v-flex md12 mx-2>
                    <label class="text-h6">{{ $t("answers") }}</label>
                    <div
                      v-for="(ans, answer_index) in questionParams.answers"
                      :key="question_index + 'ans' + answer_index"
                    >
                      <v-card
                        :elevation="0"
                        :color="questionProblem(questionParams) ? 'yellow' : ''"
                      >
                        <v-card-title v-if="questionParams.type == 'option'">
                          Option {{ +answer_index + 1 }}
                          <v-spacer />
                          <v-btn
                            v-if="questionParams.type == 'option'"
                            icon
                            @click="
                              questionParams.answers.splice(answer_index, 1)
                            "
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-checkbox
                            v-if="questionParams.type == 'option'"
                            v-model="ans.isRight"
                            :label="$t('is_right_answer')"
                            @click="answerClicked(question_index, answer_index)"
                          />

                          <VueEditor
                            v-if="questionParams.type == 'option'"
                            :id="
                              'question' +
                              question_index +
                              'answer' +
                              answer_index
                            "
                            v-model="ans.content"
                          />
                          <div v-else>
                            <v-text-field
                              v-model="ans.content"
                              :label="$t('answer')"
                              :rules="[fieldRules.required]"
                              :ref="
                                'question' +
                                question_index +
                                'answer' +
                                answer_index
                              "
                              autocomplete="off"
                            />
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>

                    <v-btn
                      v-if="questionParams.type == 'option'"
                      :elevation="0"
                      color="primary"
                      @click="
                        questionParams.answers.push({
                          content: '',
                          isRight: false,
                        })
                      "
                    >
                      {{ $t("add_option") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-draggable>

          <v-card-actions class="my-5 bottom-card-actions">
            <v-btn
              color="primary"
              :elevation="0"
              @click="addQuestion"
            >
              Add Question
            </v-btn>
            <v-btn
              @click="isImportActive = true"
              :elevation="0"
              outlined
              :color="isImportActive ? 'primary' : ''"
            >
              Import Questions
            </v-btn>
          </v-card-actions>

          <v-card
            v-if="isImportActive"
            class="drag-drop-container"
            @dragenter.prevent="highlightDropZone"
            @dragover.prevent
            @dragleave.prevent="unhighlightDropZone"
            @drop.prevent="handleFileDrop"
            :class="{ 'drag-over': isDragOver }"
          >
            <v-file-input
              v-model="selectedFile"
              label="Drag & Drop or Click to Upload"
              :multiple="false"
              accept=".csv, .docx"
              @change="handleFileChange"
              outlined
            ></v-file-input>
            <v-btn icon @click="downloadTemp">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card>
        </div>
      </div>
    </div>
    <v-dialog v-model="deleteModel" :width="600" persistent>
      <v-card>
        <v-card-title>{{ $t("delete") }}</v-card-title>
        <v-card-text>
          {{ $t("are_you_sure") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :elevation="0" @click="deleteModel = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" :elevation="0" @click="deleteClicked">
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cloneModel" :width="600" persistent>
      <v-card>
        <v-card-title>{{ $t("clone") }}</v-card-title>
        <v-card-text>
          <v-autocomplete
            :label="$t('practices')"
            v-model="chosenPracticeId"
            :items="practices"
            item-value="_id"
            item-text="name"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :elevation="0"
            :disabled="loadingSection"
            @click="(cloneModel = false), (chosenPracticeId = null)"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :elevation="0"
            @click="cloneSection"
            :loading="loadingSection"
            :disabled="loadingSection || !chosenPracticeId"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-application>
</template>

<script>
import DatePicker from "vue2-datepicker";
import VDraggable from "vuedraggable";
import "vue2-datepicker/index.css";
import "vue2-datepicker/scss/index.scss";
import Practices from "../../mixins/practices";
import Section from "../../mixins/section";
import Group from "../../mixins/group";
import Role from "../../mixins/role";
import moment from "moment";
import csvtojson from "csvtojson";
import mammoth from "mammoth";
import { VueEditor } from "vue2-editor";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const cheerio = require("cheerio/lib/slim");

async function convertDocxToHtml(arrayBuffer) {
  return new Promise((resolve, reject) => {
    mammoth
      .convertToHtml({ arrayBuffer: arrayBuffer })
      .then((result) => {
        resolve(result.value);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export default {
  components: {
    DatePicker,
    VDraggable,
    VueEditor,
  },
  mixins: [Practices, Section, Group, Role],
  data: () => ({
    deleteModel: false,
    chosenPracticeId: null,
    cloneModel: false,
    practiseLoading: false,
    isImportActive: false,
    currentSection: -1,
    questionType: {
      OPTION: "option",
      INPUT: "input",
    },
    questionTypes: ["option", "input"],
    questionColumn: {
      SINGLE: "single",
      DOUBLE: "double",
    },
    questionColumns: ["single", "double"],
    practicesParams: {
      name: null,
      startsAt: null,
      couldStartsAt: null,
      finishesAt: null,
      isActive: false,
      isForGroup: false,
      groupIds: [],
      couldReview: false,
      type: 'timedPractice'
    },
    sectionParams: {
      name: null,
      content: "",
      break: 0,
      duration: 0,
      practiceId: "",
      shuffle: false,
      shuffle_answers: true,
      questions: [],
      type: "math",
    },
    isPractice: true,
    practicesName: "",
    detail_dialog: false,
    dragover: false,
    selectedFile: null,
    isDragOver: false,
    loadingSection: false,
    config: {},
    test: "\\(E=mc^2\\)",
  }),
  watch: {
    sections: {
      deep: true,
      handler() {
        if (this.sections.length == 0 && this.practiceId) {
          this.sections = [
            {
              name: "New Section 1",
              content: "",
              duration: 0,
              break: 0,
              questions: [],
            },
          ];
        }
      },
    },
    "sectionParams.questions": {
      deep: true,
      handler() {
        if (!this.sectionParams) return;
        if (!this.sectionParams.questions) return;
      },
    },
  },
  methods: {
    addQuestion() {
      this.sectionParams.questions.push({
        isOpen: true,
        type: this.questionType.OPTION,
        column: 'single',
        content: '',
        title: '',
        explanation: '',
        answers: [
          {
            content: '',
            isRight: false,
          },
        ],
      });
      this.isImportActive = false;
      setTimeout(() => {
        const elements = document.querySelectorAll(".my-2.theme--light.elevation-0.yellow");
        if(elements.length) {
          let differY = 80; //default margin from the header
          const headerElement = document.querySelector(".practices-header");
          if(headerElement) {
            differY = headerElement.scrollHeight + 8; //8px margin from the header
          }

          window.scrollTo(0, elements[elements.length - 1].offsetTop - differY);
        }
      }, 100);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    downloadTemp() {
      const fileUrl =
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vToxJnaQlRs_2PZWsj1eHtgdZXVn8QoFC5KMEcH4dKeprX4ljSt60IheowOLgL-ISZBMSsGLpNzZxyw/pub?gid=0&single=true&output=csv";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      link.download = "file.pdf";
      link.click();
    },
    handleFileChange() {
      this.readFile(this.selectedFile);
    },
    handleFileDrop() {
      this.readFile(this.selectedFile);
    },
    highlightDropZone() {
      this.isDragOver = true;
    },
    unhighlightDropZone() {
      this.isDragOver = false;
    },
    questionProblem(question) {
      let noQuestionContent = false;
      let noQuestionAnswer = false;
      let noAnswerContent = false;
      let noRightAnswer = false;
      let noMultipleOption = false;
      noQuestionContent |= !question.content;
      noQuestionAnswer |= !question.answers.length;
      for (const answer of question.answers) {
        noAnswerContent |= !answer.content;
      }
      noRightAnswer |= !question.answers.filter((a) => a.isRight).length;

      noMultipleOption =
        question.type == "option" && question.answers.length == 1;

      const questionPr =
        noQuestionContent ||
        noQuestionAnswer ||
        noAnswerContent ||
        noRightAnswer ||
        noMultipleOption;
      return questionPr;
    },
    readFile(file) {
      if (!file) return;
      // eslint-disable-next-line no-debugger
      if (file.type != "text/csv") {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const arrayBuffer = event.target.result;
          const result = await convertDocxToHtml(arrayBuffer);
          const imageTags = result.match(
            /<img[^>]*width=['"]([^'"]*)['"][^>]*>/g
          );
          if (imageTags) {
            // Extract and display the image widths
            imageTags.forEach((tag) => {
              const widthMatch = tag.match(/width=['"]([^'"]*)['"]/);
              if (widthMatch && widthMatch[1]) {
                const imageWidth = widthMatch[1];
                console.log("Image Width:", imageWidth);
              }
            });
          } else {
            console.log("No image widths found in the document.");
          }
          const $ = cheerio.load(result);
          $("img").removeAttr("alt");
          $("img").each((index, element) => {
            $(element).attr("width", "30");
          });
          const questionParams = [];
          const text = $.html();
          // questionParams.push({
          //   content: text,
          //   title: "",
          //   explanation: "",
          //   type: "option",
          //   column: "single",
          //   answers: [{ isRight: true, content: "" }],
          //   isOpen: false,
          // });
          // console.log("DOC TEXT");
          // console.log(text);
          const questions = text.split("Question");
          for (let qIndex = 1; qIndex < questions.length; qIndex++) {
            try {
              console.log(qIndex, questions[qIndex]);
              const answerA = questions[qIndex].split("A)")[1].split("B)")[0];
              console.log("answerA", answerA);
              const answerB = questions[qIndex].split("B)")[1].split("C)")[0];
              console.log("answerB", answerB);
              const answerC = questions[qIndex].split("C)")[1].split("D)")[0];
              console.log("answerC", answerC);
              const answerD = questions[qIndex].split("D)")[1];
              console.log("answerD", answerD);

              const questionContent = questions[qIndex].split("A)")[0];
              console.log("questionContent", questionContent);

              const answerParams = [
                {
                  isRight: true,
                  content: answerA,
                },
                {
                  isRight: false,
                  content: answerB,
                },
                {
                  isRight: false,
                  content: answerC,
                },
                {
                  isRight: false,
                  content: answerD,
                },
              ];

              questionParams.push({
                content: questionContent,
                title: "",
                explanation: questions[qIndex],
                type: "option",
                column: "single",
                answers: answerParams,
                isOpen: false,
              });
            } catch (error) {
              console.log(error);
            }
          }
          this.sectionParams.questions = [
            ...this.sectionParams.questions,
            ...questionParams,
          ];
          this.isImportActive = false;
          this.selectedFile = null;
        };
        reader.readAsArrayBuffer(file);
        return;
      }
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContent = event.target.result;
        const jsonArray = await csvtojson().fromString(fileContent);

        const questions = [];

        for (const json of jsonArray) {
          const answerParams = [];
          let hasRight = false;
          Object.keys(json)
            .filter((k) => k.includes("option"))
            .map((k) => {
              if (json[k])
                answerParams.push({
                  content: json[k],
                  isRight: k.includes(json.correct),
                });
              hasRight = hasRight || k.includes(json.correct);
            });
          if (!hasRight) continue;
          if (!json.content) continue;
          const type = this.questionTypes.includes(json.type)
            ? json.type
            : this.questionType.OPTION;
          if (type == this.questionType.INPUT && answerParams.length != 1)
            continue;
          const column = this.questionColumns.includes(json.column)
            ? json.column
            : this.questionColumn.DOUBLE;
          const questionParam = {
            content: json.content,
            title: json.title || "",
            explanation: json.explanation || "",
            type,
            column,
            answers: answerParams,
            isOpen: false,
          };
          questions.push(questionParam);
        }
        this.sectionParams.questions = [
          ...this.sectionParams.questions,
          ...questions,
        ];
        this.isImportActive = false;
        this.selectedFile = null;
      };
      reader.readAsText(file);
    },
    ////
    async practiceClicked() {
      this.currentSection = -1;
      this.isPractice = true;
      if (this.practiceId) await this.sectionGet(this.practiceId);
    },
    async sectionClicked(section) {
      this.currentSection = section._id;
      this.isPractice = false;
      this.sectionParams = section;
    },
    dublicateQuestion(questionIndex) {
      const questionParam = JSON.parse(
        JSON.stringify(this.sectionParams.questions[questionIndex])
      );
      this.sectionParams.questions.splice(questionIndex + 1, 0, {
        ...questionParam,
        _id: null,
        isOpen: true,
      });
    },
    answerClicked(questionIndex, answerIndex) {
      for (
        let index = 0;
        index < this.sectionParams.questions[questionIndex].answers.length;
        index++
      ) {
        if (answerIndex == index) continue;
        this.sectionParams.questions[questionIndex].answers[
          index
        ].isRight = false;
      }
      if (
        !this.sectionParams.questions[questionIndex].answers[answerIndex]
          .isRight
      ) {
        this.sectionParams.questions[questionIndex].answers[0].isRight = true;
      }
    },
    async cloneSection() {
      if (!this.chosenPracticeId) return;
      this.loadingSection = true;
      const clonedSectionParams = Object.assign(
        {},
        {
          ...this.sectionParams,
          _id: null,
          practiceId: this.chosenPracticeId,
        }
      );
      await this.sectionCreate(clonedSectionParams);
      this.chosenPracticeId = null;
      this.loadingSection = false;
      this.cloneModel = false;
    },
    typeChanged(questionIndex) {
      const question = this.sectionParams.questions[questionIndex];
      if (question.type == "input") {
        if (this.sectionParams.questions[questionIndex].answers.length > 0) {
          this.sectionParams.questions[questionIndex].answers = [
            {
              ...this.sectionParams.questions[questionIndex].answers[0],
              isRight: true,
            },
          ];
        } else {
          this.sectionParams.questions[questionIndex].answers = [
            {
              content: "",
              isRight: true,
            },
          ];
        }
      }
    },
    async savePractice() {
      const params = {
        ...this.practicesParams,
        startsAt: moment(this.practicesParams.startsAt).toDate(),
        couldStartsAt: moment(this.practicesParams.couldStartsAt).toDate(),
      };
      if (this.practiceId) {
        await this.practiceUpdate(this.practiceId, params);
      } else {
        const save = await this.practiceCreate(params);
        if (save) {
          this.$router.push({
            query: {
              ...this.query,
              id: save.result._id,
            },
          });
          await this.sectionGet(this.practiceId);
        }
      }
      await this.sectionGet(this.practiceId);
      this.sectionParams = this.sections[0];
      this.isPractice = false;
    },
    async practicesDelete() {
      const result = await this.practiceDelete(this.practiceId);
      if (result) {
        this.$router.go(-1);
      }
    },
    async deleteClicked() {
      if (this.isPractice) {
        await this.practiceDelete(this.practiceId);
        this.$router.push( this.practicesParams.type === 'homework' ? "/homework": "/practice");
        return;
      }
      await this.sectionDelete();
      this.detail_dialog = false;
      await this.sectionGet(this.practiceId);
    },
    async saveSection() {
      this.loadingSection = true;
      this.sectionParams.practiceId = this.practiceId;
      if(!this.sectionParams.duration) {
        this.sectionParams.duration = 1
      }
      if (!this.sectionParams._id) {
        const save = await this.sectionCreate();
        if (save) {
          await this.sectionGet(this.practiceId);
        }
      } else {
        const update = await this.sectionUpdate();
        if (update) {
          await this.sectionGet(this.practiceId);
        }
      }
      this.loadingSection = false;
      if (this.sections.length) {
        this.sectionParams = this.sections[this.sections.length - 1];
      }
    },
  },
  computed: {
    cardLoading() {
      return this.sectionsLoading || this.practiseLoading;
    },
    couldAddSection() {
      return (
        this.sections.filter((s) => !s._id).length == 0 && !!this.practiceId
      );
    },
    query() {
      return this.$route.query;
    },
    practiceId() {
      return this.$route.query.id;
    },
    savePracticeDisabled() {
      return Boolean(
        !this.practicesParams.startsAt ||
          !this.practicesParams.couldStartsAt ||
          !this.practicesParams.name
      );
    },
    saveSectionDisabled() {
      let questionPr = false;
      if (this.sectionParams.questions) {
        for (const question of this.sectionParams.questions) {
          questionPr |= this.questionProblem(question);
        }
      }
      return Boolean(
        !this.sectionParams.name ||
          (this.practicesParams.type === 'timesPractice' && !this.sectionParams.duration) ||
          this.sectionParams.questions.length == 0 ||
          questionPr
      );
    },
  },
  async mounted() {
    console.log('this.practicesParams',this.practicesParams)
    this.scrollToTop();
    this.groupGetPagin();
    this.practiceGetPagin();
    this.practiseLoading = true;
    if (this.practiceId) {
      const data = await this.practiceGetById(this.practiceId);
      this.sectionGet(this.practiceId);
      this.practicesName = data.result.name;
      this.practicesParams = {
        _id: this.practiceId,
        name: data.result.name,
        startsAt: new Date(data.result.startsAt).getTime(),
        couldStartsAt: new Date(data.result.couldStartsAt).getTime(),
        isActive: data.result.isActive,
        isForGroup: data.result.isForGroup,
        groupIds: data.result.groupIds,
        couldReview: data.result.couldReview,
        type: data.result.type,
      };
    }
    this.practiseLoading = false;
  },
  async beforeMount() {
    const { type } = this.$route.query
    this.practicesParams.type = type || 'timedPractice'
  },
};
</script>

<style scoped>
.practices-header {
  top: 48px;
  border: 1px solid #EDEDED;
  position: sticky;
  position: -webkit-sticky;
  background: #ffffff;
  border-radius: 6px;
  z-index: 5;
  margin: 0 !important;
  padding: 20px;
}

.bottom-card-actions {
  position: sticky;
    bottom: 0;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #EDEDED;
}

.card-container {
  display: flex;
  height: 100%;
  margin-top: 20px;
}

.divider {
  width: 1px;
  background-color: #ccc;
  margin: 0 16px;
}

.card-left {
  width: 300px;
  height: 100%;
  overflow: hidden;
  position: sticky;
  left: 0;
  top: 90px;
}
.card-right {
  flex: 1;
  height: 100%;
}
.pointer-cursor {
  cursor: pointer;
}
.drag-drop-container {
  padding: 40px;
  border: 2px dashed #ccc;
}

.drag-over {
  border-color: #2196f3;
}
</style>
