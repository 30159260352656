import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-application',{attrs:{"no-toolbar":"","no-nav":"","auth-page":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"4","sm":"8"}},[_c(VCard,{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit()}}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("sign")))]),_c(VCardText,[_c(VTextField,{attrs:{"label":_vm.$t('login'),"autocomplete":"off","autofocus":""},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),_c(VTextField,{attrs:{"label":_vm.$t('password'),"type":"password","autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("login"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }