export default {
  activities: "Activities",
  activity: "Activity",
  activity_detial: "Activity detial",
  add_activity: "Add activity",
  add_author: "Add author",
  add_category: "Add category",
  add_events: "Add events",
  add_group: "Add Gruop",
  add_option: "Add option",
  add_order: "Add order",
  add_place: "Add place",
  add_practices: "Add practices",
  add_section: "Add section",
  add_user: "Add user",
  admin: "Admin",
  answer: "Answer",
  answers: "Answer",
  answer_content: "Answer content",
  answer_explanation: "Answer explanation",
  all: "All",
  are_you_sure: "Are you sure?",
  authors: "Authors",
  author_detial: "Author detial",
  available: "Available",
  back: "Back",
  birth_day: "Birth day",
  booked: "Booked",
  break: "Break",
  by_moderator: "By moderator",
  cancel: "Cancel",
  confirm: "Confirm",
  card: "Card",
  cash: "Cash",
  category: "Category",
  category_details: "Category details",
  categories: "Categories",
  client: "Client",
  company: "Company",
  content: "Content",
  column: "Column",
  confirmed: "Confirmed",
  continue: "Continue",
  could_review: "Could Review",
  could_start_date: "Could Start date",
  created: "Created",
  clone: "Clone",
  delete: "Delete",
  deleted_date: "Deleted date",
  details: "Details",
  duration: "Duration",
  discount: "Discount",
  email: "Email",
  event: "Event",
  event_detail: "Event detail",
  event_number: "Event number",
  event_places: "Event places",
  events: "Events",
  explanation: "Explanation",
  finish_date: "Finish date",
  finished: "Finished",
  finished_date: "Finished date",
  from_col: "From col",
  group: "Group",
  group_details: "Group Details",
  groups: "Groups",
  home_title: "SAT",
  image: "Image",
  is_active: "Active",
  is_for_group: "For Group",
  is_right_answer: "Is right answer",
  language: "English",
  layout_schema: "Layout schema",
  length_range_should_be: "Length range should be",
  login: "Login",
  moderator: "Moderator",
  name: "Name",
  new_booking: "New booking",
  new_place: "New Place",
  no: "No",
  not_paid: "Not paid",
  orders: "Orders",
  paid: "Paid",
  paid_date: "Paid date",
  password: "Password",
  payment_type: "Payment type",
  payment_state: "Payment state",
  phone: "Phone",
  place: "Place",
  places: "Places",
  place_price: "Place price",
  practices: "Practices",
  position: "Position",
  practice_results: "Practice Results",
  practices_name: "Practices name",
  price: "Price",
  process: "Process",
  questions: "Questions",
  question_content: "Question content",
  question_title: "Question Detail",
  question_explanation: "Question explanation",
  reason: "Reason",
  region: "Region",
  reject: "Reject",
  reject_detail: "Reject detail",
  rejected_places: "Rejected places",
  requested_at: "Last request time",
  right: "Right",
  role: "Role",
  row: "Row",
  rows: "Rows",
  save: "Save",
  schema: "Schema",
  seat: "Seat",
  section: "Section",
  sections: "Sections",
  shuffle: "Shuffle",
  shuffle_answers: "Shuffle answers",
  sign: "Sign",
  sold: "Sold",
  start_date: "Start date",
  started_date: "Started date",
  state: "State",
  subtotal: "Subtotal",
  surname: "Surname",
  taken_date: "Taken date",
  taken_places: "Taken places",
  this_field_can_not_be_blank: "This field can not be blank",
  time_day: "Time day",
  time_oclock: "Time oclock",
  title: "Title",
  total: "Total",
  to_col: "To col",
  type: "Type",
  user_number: "User number",
  user_name: "User name",
  user_phone: "User phone",
  username: "Username",
  user: "User",
  users: "Users",
  wrong: "Wrong",
  yes: "Yes",
  homeworks: "Homeworks",
  add_homeworks: "Add homework",
  inProcess: "In Process",
};
