<template>
  <v-application no-toolbar no-nav auth-page>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4" sm="8">
        <v-card @keyup.enter="submit()">
          <v-card-title>{{ $t("sign") }}</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="login"
              :label="$t('login')"
              autocomplete="off"
              autofocus
            />
            <v-text-field
              v-model="password"
              :label="$t('password')"
              type="password"
              autocomplete="off"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="primary" @click="submit">
              {{ $t("login") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-application>
</template>

<script>
export default {
  data: () => ({
    login: "",
    password: ""
  }),
  methods: {
    async submit() {
      await this.loginAdmin();
    },
    async loginAdmin() {
      const params = {
        login: this.login,
        password: this.password
      };
      const data = this.apiClient.convertApiResult(
        await this.apiClient.apiResult(this.urls.login, params)
      );
      if (!data) {
        return;
      }
      this.setToken(data.result.token);
      this.setUser(data);

      this.$router.push("/practice").catch(() => {});
    }
  }
};
</script>
