<template>
  <div>
    <v-layout row px-3>
      <v-flex md12>
        <v-text-field
          v-model="langObj.en"
          :label="`${name} en`"
          autocomplete="off"
        />
      </v-flex>
    </v-layout>
    <v-layout row px-3>
      <v-flex md12>
        <v-text-field
          v-model="langObj.ru"
          :label="`${name} ru`"
          autocomplete="off"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "v-lang-text",
  data: () => ({
    langObj: {},
  }),
  props: {
    value: Object,
    name: String,
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (!this.value) {
          this.langObj = {};
        } else {
          this.langObj = this.value;
        }
      },
    },
    langObj: {
      deep: true,
      handler() {
        this.$emit("input", this.langObj);
      },
    },
  },
  mounted() {
    if (!this.value) {
      this.langObj = {};
    } else {
      this.langObj = this.value;
    }
  },
};
</script>
