<template>
  <v-application :toolbar_title="$t('users')">
    <v-crud
      :addRole="'userCreate'"
      :editRole="'userUpdate'"
      :deleteRole="'userDelete'"
      :addName="'add_user'"
      :headers="headers"
      :data="users"
      :toolbar_title="$t('users')"
      :paginationLength="pagination_length"
      :page="page"
      @row-click="rowClick"
      @cancel-clicked="resetForm"
      :dialogWidth="600"
      :cardTitle="$t('user_number')"
      @page-changed="pageChanged"
      @add-clicked="detail_dialog = true"
      :detailDialog="detail_dialog"
      @save-clicked="save"
      :saveDisabled="saveDisabled"
      @update-data="searchUser"
      no-select
      can-delete
      @delete-clicked="deleteClicked"
    >
      <template v-slot:detail>
        <v-form v-model="is_valid" v-on:submit.prevent>
          <v-text-field
            v-model="user.name"
            :label="$t('name')"
            :rules="[fieldRules.lengthRange(user.name, 3, 100)]"
            ref="name"
            autocomplete="off"
          />
          <v-text-field
            v-model="user.login"
            :label="$t('login')"
            :rules="[fieldRules.lengthRange(user.login, 3, 100)]"
            ref="login"
            :disabled="!!user.tgId"
            autocomplete="off"
          />
          <v-text-field
            v-model="user.password"
            :label="$t('password')"
            ref="password"
            autocomplete="off"
          />
          <v-text-field
            v-model="user.phone"
            :label="$t('phone')"
            v-mask="masks.phone"
            ref="phone"
            autocomplete="off"
          />
          <v-text-field
            v-model="user.email"
            :label="$t('email')"
            ref="email"
            :rules="emailRules"
          />
        </v-form>
      </template>
    </v-crud>
  </v-application>
</template>

<script>
import Users from "../../mixins/user";

export default {
  data: () => ({
    user: {
      name: null,
      login: null,
      password: null,
      phone: null,
      email: null,
    },
    pagination_length: 1,
    page: 1,
    detail_dialog: false,
    is_valid: false,
    emailRules: [
      (v) => (v ? /.+@.+\..+/.test(v) || "E-mail must be valid" : true),
    ],
  }),
  mixins: [Users],
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "name",
        },
        {
          text: this.$t("phone"),
          value: "phone",
        },
        {
          text: this.$t("login"),
          value: "login",
        },
        {
          text: this.$t("email"),
          value: "email",
        },
        {
          text: this.$t("requested_at"),
          value: "requestedAt",
        },
        {
          text: this.$t("image"),
          value: "image",
        },
        {
          text: null,
          align: "right",
          value: "table_actions",
        },
      ];
    },
    form() {
      const user = {
        name: this.user.name,
        login: this.user.login,
      };
      if (!this.user._id) {
        user.password = this.user.password;
      }
      return user;
    },
    saveDisabled() {
      let formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;
      });
      return formHasErrors;
    },
  },
  watch: {
    page() {
      this.userGetPagin();
    },
    detail_dialog() {
      if (!this.detail_dialog) {
        this.resetForm();
      }
    },
  },
  methods: {
    async save() {
      let result;
      if (!this.user._id) {
        result = await this.userCreate();
      } else {
        result = await this.userUpdate();
      }
      if (result) {
        this.resetForm();
        this.userGetPagin();
      }
    },
    async deleteClicked(user) {
      const result = await this.userDelete(user);
      if (result) {
        this.resetForm();
        this.userGetPagin();
      }
    },
    searchUser(search) {
      this.search_user = search;
      this.userGetPagin();
    },
    pageChanged(page) {
      this.page = page;
    },
    resetForm() {
      Object.keys(this.form).forEach((f) => {
        if (this.$refs[f]) this.$refs[f].reset();
      });
      this.detail_dialog = false;
      this.user = {
        name: null,
        login: null,
        password: null,
        phone: null,
        email: null,
      };
    },
    rowClick(item) {
      this.user = this.$copy(item);
      this.detail_dialog = true;
    },
  },
  mounted() {
    this.userGetPagin();
  },
};
</script>
