
export default {
    data: () => ({
        users: [],
        search_user: ''
    }),
    computed: {
        searched_users() {
            const searched_users = []
            this.users.map(
                u => {
                    if (u.name) {
                        let user_name = u.name;
                        if (u.surname) {
                            user_name += ` ${u.surname}`
                        }
                        if (u.company_name) {
                            user_name += ` - ${u.company_name}`
                        }

                        searched_users.push({
                            text: user_name,
                            value: u._id,
                            additional: u.phone_number
                        })
                    }
                }
            )
            return searched_users;
        }
    },
    methods: {
        async userGetPagin() {
            const params = {
                limit: 50000,
                page: this.page ? this.page : 1,
                search : this.search_user
            }
            const user_get_pagin = JSON.parse(JSON.stringify(this.urls.user_get_pagin));
            user_get_pagin.url += `?limit=${params.limit}&page=${params.page}&search=${params.search}`;
            const result = this.apiClient.convertApiResult(
                await this.apiClient.apiResult(user_get_pagin)
            );
            if (result && result.result.data instanceof Array) {
                this.users = result.result.data;
                this.pagination_length = Math.ceil(result.result.total / 10)
            }
            else {
                this.users = []
                this.pagination_length = 1
            }
        },
        async userCreate() {
            return this.apiClient.convertApiResult(
                await this.apiClient.apiResult(this.urls.user_create, this.user)
            );
        },
        async userUpdate() {
            const user_update = JSON.parse(JSON.stringify(this.urls.user_update));
            user_update.url += `${this.user._id}`
            return this.apiClient.convertApiResult(
                await this.apiClient.apiResult(user_update, this.user)
            );
        },
        async userDelete(user) {
            const user_delete = JSON.parse(JSON.stringify(this.urls.user_delete));
            user_delete.url += `${user._id}`
            return this.apiClient.convertApiResult(
                await this.apiClient.apiResult(user_delete)
            );
        }
    }
}
