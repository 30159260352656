<template>
  <v-layout row px-4>
    <v-btn tile x-small :height="36" @click="leftRightClicked(true)">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-divider vertical />

    <v-menu
      v-model="menu"
      transition="scale-transition"
      min-width="500px"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn tile v-on="on">
          <v-icon class="mr-3">mdi-calendar-range</v-icon>
          {{ datesRangeText }}
        </v-btn>
      </template>
      <v-card>
        <div style="display: grid; grid-template-columns: auto auto auto;">
          <div style="width: 300px" class="ma-0 pa-0">
            <v-date-picker v-model="dates" range no-title />
            <v-layout row px-5>
              <v-flex md6 px-2>
                <v-text-field
                  v-model="dates[0]"
                  :label="$t('start_date')"
                  v-mask="date_mask"
                />
              </v-flex>
              <v-flex md6 px-2>
                <v-text-field
                  v-model="dates[1]"
                  :label="$t('end_date')"
                  v-mask="date_mask"
                />
              </v-flex>
            </v-layout>
          </div>
          <v-divider vertical class="ma-0 pa-0" />
          <div style="width: 190px; padding-top: 3px;">
            <p class="button ma-0" @click="setDateByParagraph(0)">
              {{ $t("today") }}
            </p>
            <p class="button ma-0" @click="setDateByParagraph(1)">
              {{ $t("yesterday") }}
            </p>
            <p class="button ma-0" @click="setDateByParagraph(2)">
              {{ $t("this_week") }}
            </p>
            <p class="button ma-0" @click="setDateByParagraph(3)">
              {{ $t("last_week") }}
            </p>
            <p class="button ma-0" @click="setDateByParagraph(4)">
              {{ $t("this_month") }}
            </p>
            <p class="button ma-0" @click="setDateByParagraph(5)">
              {{ $t("last_month") }}
            </p>
            <p class="button ma-0" @click="setDateByParagraph(6)">
              {{ $t("last_7_days") }}
            </p>
            <p class="button ma-0" @click="setDateByParagraph(7)">
              {{ $t("last_30_days") }}
            </p>
          </div>
        </div>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="cancelClicked"> {{ $t("cancel") }}</v-btn>
          <v-btn text color="primary" @click="doneClicked">
            {{ $t("done") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-divider vertical />
    <v-btn
      tile
      x-small
      :height="36"
      :style="next_disabled ? 'cursor: default' : ''"
      @click="leftRightClicked(false)"
    >
      <v-icon :disabled="next_disabled">mdi-chevron-right</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    dates: ["2021-03-03", "2021-04-04"],
    menu: false,
    date_mask: "####-##-##"
  }),
  watch: {
    dates: {
      deep: true,
      handler() {
        if (this.dates instanceof Array && this.dates.length == 2) {
          if (
            new Date(this.dates[0]).getTime() >
            new Date(this.dates[1]).getTime()
          ) {
            this.dates = [this.dates[1], this.dates[0]];
          }
          const end_date = new Date(this.dates[1]);
          const current_date = new Date();
          if (end_date > current_date) {
            this.dates[1] = this.date_yyyy_mm_dd(current_date);
          }
        }
      }
    },
    value: {
      deep: true,
      handler() {
        this.dates = this.value;
      }
    }
  },
  computed: {
    datesRangeText() {
      if (this.dates[0] == this.dates[1]) {
        return this.dates[0];
      }
      return this.dates.join(" ~ ");
    },
    next_disabled() {
      if (this.dates.length == 2) {
        const next_date = new Date(this.dates[1]);
        const current_date = new Date();
        return (
          current_date.getFullYear() == next_date.getFullYear() &&
          current_date.getMonth() == next_date.getMonth() &&
          current_date.getDate() == next_date.getDate()
        );
      }
      return false;
    }
  },
  props: {
    value: {
      type: Array
    }
  },
  methods: {
    setDateByParagraph(type) {
      const dates = [];
      switch (type) {
        case 0: {
          const result = this.date_yyyy_mm_dd(new Date());
          dates.push(result);
          dates.push(result);
          break;
        }
        case 1: {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          const result = this.date_yyyy_mm_dd(date);
          dates.push(result);
          dates.push(result);
          break;
        }
        case 2: {
          const current_date = new Date();
          const start_date = new Date();
          const end_date = new Date();
          start_date.setDate(
            current_date.getDate() - (6 - current_date.getDay())
          );
          const start_result = this.date_yyyy_mm_dd(start_date);
          dates.push(start_result);
          const end_result = this.date_yyyy_mm_dd(end_date);
          dates.push(end_result);
          break;
        }
        case 3: {
          const current_date = new Date();
          const start_date = new Date();
          const end_date = new Date();
          start_date.setDate(
            current_date.getDate() - (6 - current_date.getDay()) - 7
          );
          const start_result = this.date_yyyy_mm_dd(start_date);
          dates.push(start_result);

          end_date.setDate(
            current_date.getDate() - (7 - current_date.getDay())
          );
          const end_result = this.date_yyyy_mm_dd(end_date);
          dates.push(end_result);
          break;
        }
        case 4: {
          const start_date = new Date();
          const end_date = new Date();
          start_date.setDate(1);
          const start_result = this.date_yyyy_mm_dd(start_date);
          dates.push(start_result);
          const end_result = this.date_yyyy_mm_dd(end_date);
          dates.push(end_result);
          break;
        }
        case 5: {
          const current_date = new Date();
          const start_date = new Date();
          const end_date = new Date();
          start_date.setMonth(current_date.getMonth() - 1, 1);
          const start_result = this.date_yyyy_mm_dd(start_date);
          dates.push(start_result);
          end_date.setMonth(current_date.getMonth(), 1);
          end_date.setDate(start_date.getDate() - 1);
          const end_result = this.date_yyyy_mm_dd(end_date);
          dates.push(end_result);
          break;
        }
        case 6: {
          const current_date = new Date();
          const start_date = new Date();
          const end_date = new Date();
          start_date.setDate(current_date.getDate() - 7);
          const start_result = this.date_yyyy_mm_dd(start_date);
          dates.push(start_result);
          const end_result = this.date_yyyy_mm_dd(end_date);
          dates.push(end_result);
          break;
        }
        case 7: {
          const current_date = new Date();
          const start_date = new Date();
          const end_date = new Date();
          start_date.setDate(current_date.getDate() - 30);
          const start_result = this.date_yyyy_mm_dd(start_date);
          dates.push(start_result);
          const end_result = this.date_yyyy_mm_dd(end_date);
          dates.push(end_result);
          break;
        }
      }
      this.dates = dates;
      this.emitInput();
      this.menu = false;
    },
    leftRightClicked(is_left = true) {
      const start_date = new Date(this.dates[0]);
      const end_date = new Date(this.dates[1]);
      let differ = (end_date - start_date) / (1000 * 60 * 60 * 24);
      if (differ == 0) {
        differ = 1;
      }

      if (is_left) {
        start_date.setDate(start_date.getDate() - differ);
        end_date.setDate(end_date.getDate() - differ);
      } else {
        if (!this.next_disabled) {
          start_date.setDate(start_date.getDate() + differ);
          end_date.setDate(end_date.getDate() + differ);
        }
      }
      this.dates = [
        this.date_yyyy_mm_dd(start_date),
        this.date_yyyy_mm_dd(end_date)
      ];
      this.emitInput();
    },
    emitInput() {
      this.$emit("input", this.dates);
    },
    cancelClicked() {
      this.dates = this.value;
      this.menu = false;
    },
    doneClicked() {
      this.emitInput();
      this.menu = false;
    }
  },
  mounted() {
    this.dates = this.value;
  }
};
</script>

<style>
.button {
  cursor: pointer;
  user-select: none;
  padding: 9px 16px;
}
.button:hover {
  color: black;
  background-color: lightgrey;
}
</style>
