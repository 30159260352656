
const sign = {
    login: { url: "/login", method: "POST" }
}
const user = {
    user_get_pagin: { url: "/users", method: "GET" },
    user_create: { url: "/users", method: "POST" },
    user_update: { url: "/users/", method: "PUT" },
    user_delete: { url: "/users/", method: "DELETE" },
}
const group = {
    group_get_pagin: { url: "/groups", method: "GET" },
    group_create: { url: "/groups", method: "POST" },
    group_update: { url: "/groups/", method: "PUT" },
    group_delete: { url: "/groups/", method: "DELETE" },
}
const practice = {
    practice_get_pagin: { url: "/practices", method: "GET" },
    practice_get_by_id: { url: "/practices/", method: "GET" },
    practice_create: { url: "/practices", method: "POST" },
    practice_update: { url: "/practices/", method: "PUT" },
    practice_delete: { url: "/practices/", method: "DELETE" },
    practice_result_get: { url: "/practice-result/", method: "GET" },
    practice_user_retry: { url: "/practices/user-retry", method: "POST" },
}
const section = {
    section_get: { url: "/sections/", method: "GET" },
    section_create: { url: "/sections", method: "POST" },
    section_update: { url: "/sections/", method: "PUT" },
    section_delete: { url: "/sections/", method: "DELETE" },
}
const question = {
    question_get: { url: "/questions/", method: "GET" },
    question_create: { url: "/questions", method: "POST" },
    question_update: { url: "/questions/", method: "PUT" },
    question_delete: { url: "/questions/", method: "DELETE" },
}
const category = {
    category_get: { url: "/categories/", method: "GET" },
    category_with_practices_get: { url: "/categories/with-practice", method: "GET" },
    category_create: { url: "/categories", method: "POST" },
    category_update: { url: "/categories/", method: "PUT" },
    category_delete: { url: "/categories/", method: "DELETE" },
}
const role = {
    role_get_pagin: { url: "/roles", method: "GET" },
    role_current: { url: "/roles/current", method: "GET" },
    role_create: { url: "/roles", method: "POST" },
    role_update: { url: "/roles/", method: "PUT" },
    role_delete: { url: "/roles/", method: "DELETE" },
}
const admin = {
    admin_get_pagin: { url: "/admins", method: "GET" },
    admin_create: { url: "/admins", method: "POST" },
    admin_update: { url: "/admins/", method: "PUT" },
    admin_delete: { url: "/admins/", method: "DELETE" },
}
export default {
    ...sign,
    ...user,
    ...group,
    ...practice,
    ...section,
    ...question,
    ...category,
    ...role,
    ...admin,
}
