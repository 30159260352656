<template>
  <v-application :toolbar_title="$t('questions')">
    <v-card v-if="!isPreview">
      <v-card-title> {{ 
          $t("question_title")
        }}
        <v-spacer></v-spacer>
        <v-btn text outlined @click="isPreview = true">Preview</v-btn>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="questionSettings.content">
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("question_content")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <wysiwyg v-model="question.content" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-model="questionSettings.explanation">
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("question_explanation")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <wysiwyg v-model="question.explanation" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-model="questionSettings.answer">
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("answers")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(ans, ind) in question.answers" :key="ind">
                <v-card :elevation="0">
                  <v-card-title>Option {{ +ind + 1 }}</v-card-title>
                  <v-card-text>
                    <v-checkbox
                      v-model="ans.isRight"
                      :label="$t('is_right_answer')"
                    />

                    <v-expansion-panels
                      v-model="questionSettings.answers[ind].content"
                    >
                      <v-expansion-panel :elevation="0">
                        <v-expansion-panel-header>{{
                          $t("answer_content")
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <wysiwyg v-model="ans.content" />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels
                      v-model="questionSettings.answers[ind].explanation"
                    >
                      <v-expansion-panel :elevation="0">
                        <v-expansion-panel-header>{{
                          $t("answer_explanation")
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <wysiwyg v-model="ans.explanation" />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="removeOption(ind)" icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
              <v-btn @click="addOption">
                {{ $t("add_option") }}
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-btn icon> <v-icon>mdi-delete</v-icon> </v-btn>
        <v-btn text @click="cancel"> {{ $t("cancel") }} </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="saveQuestion">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        Question Detail
        <v-spacer></v-spacer>
        <v-btn text outlined @click="isPreview = false">Back</v-btn>
      </v-card-title>
      <v-card-text>
        <div v-html="question.content"></div>
        <div v-html="question.explanation"></div>
        <div
          style="border: solid green 1px"
          v-for="(ans, ind) in question.answers"
          :key="'P' + ind"
        >
          <div v-html="ans.content"></div>
          <div v-html="ans.explanation"></div>
        </div>
      </v-card-text>
    </v-card>
  </v-application>
</template>

<script>
import Questions from "../../mixins/questions";
export default {
  components: {},
  mixins: [Questions],
  data: () => ({
    isPreview: false,
    questionSettings: {
      content: 0,
      explanation: null,
      answer: 0,
      answers: [
        {
          content: 0,
          explanation: null,
        },
        {
          content: 0,
          explanation: null,
        },
        {
          content: 0,
          explanation: null,
        },
        {
          content: 0,
          explanation: null,
        },
      ],
    },
    question: {
      content: "",
      explanation: "",
      type: "option",
      column: "double",
      answers: [
        {
          content: "",
          explanation: "",
          isRight: false,
        },
        {
          content: "",
          explanation: "",
          isRight: false,
        },
        {
          content: "",
          explanation: "",
          isRight: false,
        },
        {
          content: "",
          explanation: "",
          isRight: false,
        },
      ],
    },
  }),
  methods: {
    async saveQuestion() {
      this.questionCreate();
    },
    addOption() {
      this.question.answers.push({
        content: "",
        explanation: "",
        isRight: false,
      });
      this.questionSettings.answers.push({
        content: 0,
        explanation: null,
      });
    },
    removeOption(ind) {
      this.question.answers.splice(ind, 1);
      this.questionSettings.answers.splice(ind, 1);
    },
    cancel() {
      this.$router.push(`/questions`);
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panel::before {
  box-shadow: none;
}
</style>
