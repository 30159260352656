export default {
  data: () => ({
    sections: [],
    sectionsLoading: false,
  }),
  methods: {
    async sectionGet(id: string) {
      this.sectionsLoading = true;
      const section_get = JSON.parse(JSON.stringify(this.urls.section_get));
      section_get.url += `${id}`;
      const data = await this.apiClient.convertApiResult(
        await this.apiClient.apiResult(section_get)
      );
      const sections = data.result;
      for (const section of sections) {
        section.questions = section.questions.map((q) => {
          return {
            ...q,
            isOpen: false,
          };
        });
      }
      this.sections = sections;
      this.sectionsLoading = false;
    },
    async sectionCreate(sectionParams = null) {
      if (!sectionParams) sectionParams = this.sectionParams;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(this.urls.section_create, sectionParams)
      );
    },
    async sectionUpdate() {
      const section_update = JSON.parse(
        JSON.stringify(this.urls.section_update)
      );
      section_update.url += `${this.sectionParams._id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(section_update, this.sectionParams)
      );
    },
    async sectionDelete() {
      const section_delete = JSON.parse(
        JSON.stringify(this.urls.section_delete)
      );
      section_delete.url += `${this.sectionParams._id}`;
      return this.apiClient.convertApiResult(
        await this.apiClient.apiResult(section_delete)
      );
    },
  },
};
