export default {
  name: "userable",
  data: () => ({
    user: null,
    token: null
  }),
  computed: {
    computedUser() {
      return this.$store.state.user;
    },
    computedToken() {
      return this.$store.state.token;
    }
  },
  methods: {
    authorize() {
      this.user = JSON.parse(JSON.stringify(this.computedUser));
      this.token = this.computedToken;
      this.apiClient.auth(this.user, this.token, this);
    }
  },
  mounted() {
    if (this.computedUser) {
      this.authorize()
    }
  },
  beforeMount() {
    if (this.computedUser) {
      this.authorize()
    }
    else {
      this.logout()
    }
  }
};
