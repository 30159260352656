
import Vue from 'vue'
import VueRouter,{RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import User from '../views/User/User.vue'
import Auth from "../views/Auth.vue"
import Practice from '../views/Practices/Practices.vue'
import Homework from '../views/Homework/Homework.vue'
import PracticesDetail from '../views/Practices/PracticesDetail.vue'
import QuestionDetail from '../views/Questions/QuestionDetail.vue'
import Question from '../views/Questions/Question.vue'
import Group from '../views/Group/Group.vue'
import Category from '../views/Category/Category.vue'
import PracticeResult from '../views/Practices/PracticeResult.vue'
import Role from '../views/Role/Role.vue'
import Admin from '../views/Admin/Admin.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/signin",
    component: Auth
  },
  {
    path: "/user",
    component: User
  },
  {
    path: "/practice",
    component: Practice
  },
  {
    path: "/homework",
    component: Homework
  },
  {
    path: "/practices/detail",
    component: PracticesDetail
  },
  {
    path: "/questions/detail",
    component: QuestionDetail
  },
  {
    path: "/questions",
    component: Question
  },
  {
    path: "/group",
    component: Group
  },
  {
    path: "/category",
    component: Category
  },
  {
    path: "/practice-result",
    component: PracticeResult
  },
  {
    path: "/role",
    component: Role
  },
  {
    path: "/admin",
    component: Admin
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
