<template>
  <v-application :toolbar_title="$t('groups')">
    <v-crud
      :addRole="'groupCreate'"
      :editRole="'groupUpdate'"
      :deleteRole="'groupDelete'"
      :addName="'add_group'"
      :headers="headers"
      :data="groups"
      :toolbar_title="$t('groups')"
      :paginationLength="pagination_length"
      :page="page"
      @row-click="rowClick"
      @cancel-clicked="resetForm"
      :dialogWidth="600"
      :cardTitle="$t('group_details')"
      @page-changed="pageChanged"
      @add-clicked="detail_dialog = true"
      :detailDialog="detail_dialog"
      @save-clicked="save"
      :saveDisabled="saveDisabled"
      @update-data="searchGroup"
      no-select
      can-delete
      @delete-clicked="deleteClicked"
    >
      <template v-slot:detail>
        <v-form v-model="is_valid" v-on:submit.prevent>
          <v-text-field
            v-model="group.name"
            :label="$t('name')"
            :rules="[fieldRules.lengthRange(group.name, 3, 100)]"
            ref="name"
            autocomplete="off"
          />
          <v-autocomplete
            v-model="group.userIds"
            :label="$t('users')"
            ref="users"
            autocomplete="off"
            :items="users"
            :item-text="getItemText"
            multiple
            item-value="_id"
            chips
          />
        </v-form>
      </template>
    </v-crud>
  </v-application>
</template>

<script>
import Users from "../../mixins/user";
import Groups from "../../mixins/group";

export default {
  data: () => ({
    group: {
      name: null,
      userIds: [],
    },
    pagination_length: 1,
    page: 1,
    detail_dialog: false,
    is_valid: false,
    emailRules: [
      (v) => (v ? /.+@.+\..+/.test(v) || "E-mail must be valid" : true),
    ],
  }),
  mixins: [Users, Groups],
  computed: {
    headers() {
      return [
        {
          text: this.$t("name"),
          value: "name",
        },
        {
          text: this.$t("users"),
          value: 'userIds',
        },
        {
          text: null,
          align: "right",
          value: "table_actions",
        },
      ];
    },
    form() {
      const group = {
        name: this.group.name,
      };
      return group;
    },
    saveDisabled() {
      let formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;
      });
      return formHasErrors;
    },
  },
  watch: {
    page() {
      this.groupGetPagin();
    },
    detail_dialog() {
      if (!this.detail_dialog) {
        this.resetForm();
      }
    },
  },
  methods: {
    getItemText(user) {
      return `${user.name || ""} ${user.login || ""} ${
        user.phone || ""
      }`.trim();
    },
    async save() {
      let result;
      if (!this.group._id) {
        result = await this.groupCreate();
      } else {
        result = await this.groupUpdate();
      }
      if (result) {
        this.resetForm();
        this.groupGetPagin();
      }
    },
    async deleteClicked(group) {
      const result = await this.groupDelete(group);
      if (result) {
        this.resetForm();
        this.groupGetPagin();
      }
    },
    searchGroup(search) {
      this.search_group = search;
      this.groupGetPagin();
    },
    pageChanged(page) {
      this.page = page;
    },
    resetForm() {
      Object.keys(this.form).forEach((f) => {
        if (this.$refs[f]) this.$refs[f].reset();
      });
      this.detail_dialog = false;
      this.group = {
        name: null,
        userIds: [],
      };
    },
    rowClick(item) {
      this.group = this.$copy(item);
      this.detail_dialog = true;
    },
  },
  mounted() {
    this.groupGetPagin();
    this.userGetPagin();
  },
};
</script>
