<template>
  <v-menu offset-y transition="scale-transition" min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-bind:outlined="!solo"
        dense
        v-bind:hide-details="noHideDetails == false"
        v-bind:solo="solo"
        v-bind:label="label"
        prepend-inner-icon="mdi-calendar-range"
        v-bind:value="computed_value"
        readonly
      />
    </template>
    <v-date-picker
      no-title
      v-model="date"
      v-bind:min="min"
      v-bind:max="max"
      :locale="locale"
    />
  </v-menu>
</template>
<script>
export default {
  name: "v-datetime-picker",
  data: () => ({
    date: null,
    locale: "ru"
  }),
  props: {
    noHideDetails: Boolean,
    solo: Boolean,
    label: String,
    value: String,
    min: String,
    max: String
  },
  computed: {
    computed_value() {
      if (this.date) return this.date_ddmmyy(new Date(this.date).getTime());
      return null;
    },
    computed_user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if (this.value) this.date = this.value;
  },
  watch: {
    date() {
      this.$emit("input", this.date);
    },
    value() {
      this.date = this.value;
    }
  }
};
</script>
