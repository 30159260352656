import axios from "axios";

export type Method =
  | "get"
  | "GET"
  | "delete"
  | "DELETE"
  | "head"
  | "HEAD"
  | "options"
  | "OPTIONS"
  | "post"
  | "POST"
  | "put"
  | "PUT"
  | "patch"
  | "PATCH"
  | "link"
  | "LINK"
  | "unlink"
  | "UNLINK";

type Options = {
  url: string;
  method: Method;
};

export default class ApiClient extends Function {
  baseUrl: string;
  socketUrl: string;
  user: object;
  token: string | undefined;
  app: any;

  constructor(options: any = {}) {
    super();
    this.baseUrl = options.baseUrl;
    this.socketUrl = options.socketUrl;
  }

  async http(url: string, method: Method, data = {}) {
    return await axios({
      baseURL: this.baseUrl,
      url: url,
      method: method,
      data: data,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${this.token}`
      }
    });
  }

  auth(user: object, token: string, app) {
    this.user = user;
    this.token = token;
    this.app = app;
  }

  getErrorNote(code) {
    switch (code) {
      case 0: {
        return 'Success'
      }
      case 55001: {
        return 'Validation error'
      }
      case 55002: {
        return 'Invalid upload type'
      }
      case 55003: {
        return 'You dont have permission to perform this operation'
      }
      case 54000: {
        return 'Client not found'
      }
      case 54001: {
        return 'Client already exists'
      }
      case 54002: {
        return 'Code does not match'
      }
      case 54003: {
        return 'Too frequent attempts'
      }
      case 54004: {
        return 'Sending sms to this number  failed'
      }
      case 54005: {
        return 'Incorrent code '
      }
      case 54006: {
        return 'Otp expired'
      }
      case 54007: {
        return 'Too many tries try again later'
      }
      case 54008: {
        return 'Too many attempts'
      }
      case 56000: {
        return 'Driver not found'
      }
      case 56001: {
        return 'Driver already exists'
      }
      case 57001: {
        return 'Item already exists'
      }
      case 57002: {
        return 'Item not found'
      }
      case 58001: {
        return 'Category already exists'
      }
      case 58002: {
        return 'Category not found'
      }
      case 59001: {
        return 'Order already exists'
      }
      case 59002: {
        return 'Order not found'
      }
      case 60000: {
        return 'Moderator not found'
      }
      default: {
        return 'Unknown error'
      }
    }
  }

  setSnackbar(data) {
    try {
      const params = {
        show: data.code ? true : false,
        color: data.code ? 'success' : 'error',
        text: this.getErrorNote(data.code),
        updated_time: new Date().getTime()
      };

      this.app.$store.commit('setSnackbar', params);
    }
    catch (error) {
      //
    }
  }

  async apiResult(options: Options, data = {}) {
    try {
      if (this.app) { this.app.requestCount++; }
      const result = await this.http(options.url, options.method, data);
      if (this.app) { this.app.responseCount++; }
      if (result && result.data)
        this.setSnackbar(result.data)
      return result;
    } catch (error) {
      if (this.app) { this.app.responseCount++; }
      console.log(error)
      if (error && error.response && error.response.data)
        this.setSnackbar(error.response.data)
      return error.response;
    }
  }

  convertApiResult(response) {
    if (response && response.data && response.status == 200) {
      return response.data;
    } else if (response && response.data && response.data.statusCode == 401) {
      if (this.app) {
        this.app.logout();
        window.location.href = '/signin'
      }
    }
    return null;
  }

  async uploadFile(file: any) {
    const headers = {
      Authorization: this.token
    };
    const formdata = new FormData();
    formdata.append("file", file);
    const result = await axios.post(this.baseUrl + "/upload-file", formdata, {
      headers
    });
    if (result && result.data && result.data.data) {
      return result.data.data;
    } else {
      return null;
    }
  }
}
