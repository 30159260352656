<template>
  <v-app style="background-color: rgb(242, 242, 242); user-select: none">
    <div
      id="snackbar"
      :class="[snackbar.show ? 'show' : '']"
      style="z-index: 5000"
      :style="'background-color: ' + snackbar.color"
    >
      {{ snackbar.text }}
    </div>
    <v-app-bar
      v-if="!noToolbar"
      app
      color="primary"
      dark
      dense
      loading
      clipped-left
      clipped-right
    >
      <v-progress-linear
        class="ma-0 pa-0"
        top
        absolute
        color="secondary"
        height="5"
        value="0"
        :background-opacity="0.1"
        :active="waitingResponse"
        :indeterminate="waitingResponse"
      />
      <v-app-bar-nav-icon @click="menuClicked" />
      <v-toolbar-title> {{ toolbar_title }} </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="confirm_logout = true">
        <v-icon color="red">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-if="!noNav"
      v-model="navigation"
      :mini-variant="minivariant"
      :mini-variant-width="80"
      app
      clipped
      width="180"
    >
      <v-list class="py-0">
        <template v-for="(nav, nav_index) in navs">
          <template v-if="currentRole[nav.role]">
            <v-list-item v-bind:key="nav_index" v-bind:to="nav.link">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-avatar
                    v-bind="attrs"
                    v-on="minivariant ? on : {}"
                  >
                    <v-icon v-bind:color="nav.color">{{ nav.icon }}</v-icon>
                  </v-list-item-avatar>
                </template>
                <span>{{ $t(`${nav.title}`) }}</span>
              </v-tooltip>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`${nav.title}`) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container :class="authPage ? 'fill-height' : ''" :fluid="authPage">
        <slot />
      </v-container>
    </v-main>

    <v-dialog v-model="confirm_logout" :width="400">
      <v-card>
        <v-card-title>{{ $t("are_you_sure") }}</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="confirm_logout = false">{{ $t("no") }}</v-btn>
          <v-btn text color="primary" @click="logout">{{ $t("yes") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Role from "../mixins/role";
import Userable from "../mixins/userable";

export default {
  name: "v-application",
  mixins: [Userable, Role],
  data: () => ({
    navigation: true,
    minivariant: false,
    menu_current: 0,
    requestCount: 0,
    responseCount: 0,
    navStates: [],
    screen_width: 0,
    confirm_logout: false,
  }),
  props: {
    noNav: Boolean,
    noToolbar: Boolean,
    authPage: Boolean,
    toolbar_title: String,
  },
  computed: {
    navs() {
      if (!this.user) return [];
      const navs = this.$copy(this.$store.state.navs);
      return navs;
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
    waitingResponse() {
      return this.requestCount > this.responseCount;
    },
  },
  methods: {
    menuClicked() {
      if (this.screen_width > 1400) {
        this.minivariant = !this.minivariant;
        this.navigation = true;
      } else {
        this.navigation = !this.navigation;
      }
    },
    navClicked(index) {
      this.menu_current = index;
    },
    logout() {
      this.$store.commit("signOut");
      this.$router.push("/signin").catch(() => {});
    },
    async refresh() {
      this.apiClient.convertApiResult(
        await this.apiClient.apiResult(this.urls.refresh)
      );
    },
  },
  mounted() {
    if(this.user) {
      this.roleCurrent();
    }
  },
  beforeMount() {
    this.navStates = this.$store.state.navStates;
    this.screen_width = window.innerWidth;
    if (this.screen_width > 1400) {
      this.minivariant = this.$store.state.minivariant;
      this.navigation = true;
    }
  },
  watch: {
    navStates: {
      deep: true,
      handler() {
        this.$store.commit("setNavStates", this.navStates);
      },
    },
    minivariant: {
      deep: true,
      handler() {
        this.$store.commit("setMinivariant", this.minivariant);
        if (this.minivariant) {
          for (const index in this.navStates) {
            this.navStates[index] = false;
          }
        }
      },
    },
    snackbar: {
      deep: true,
      handler() {
        if (!this.snackbar.show) {
          return;
        }
        setTimeout(() => {
          const current_time = new Date().getTime();
          if (current_time - this.snackbar.updated_time > 2500) {
            this.snackbar.show = false;
          }
        }, 3000);
      },
    },
  },
};
</script>

<style>
  #snackbar {
    min-width: 250px;
    background-color: #EC6C6C;
    color: #fff;
    text-align: center;
    padding: 10px 30px;
    position: fixed;
    margin-left: -125px;
    left: 50%;
    top: 30px;
    size: 18px;
    font-family: "Open-Sans";
    border-radius: 4px;
    visibility: hidden;
  }

  #snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 1s, fadeout 0.5s 2.5s;
    animation: fadein 1s, fadeout 0.5s 2.5s;
  }

  @keyframes fadein {
    0% {
      top: 0;
      opacity: 0;
    }
    100% {
      top: 30px;
      opacity: 1;
    }
  }

  @keyframes fadeout {
    100% {
      top: 30px;
      opacity: 1;
    }
    0% {
      top: 0;
      opacity: 0;
    }
  }

  @-webkit-keyframes fadein {
    0% {
      top: 0;
      opacity: 0;
    }
    100% {
      top: 30px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    100% {
      top: 30px;
      opacity: 1;
    }
    0% {
      top: 0;
      opacity: 0;
    }
  }
</style>
